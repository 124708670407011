import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Table, Spin } from "antd";
import moment from "moment";
// Redux
import { connect } from "react-redux";
import { getUserRoleId, getSelectedDepartmentId } from "redux/selectors";
import {
  addDaysinDate,
  getKenyanDateTime,
  getMySqlDate,
  getinitialdate,
} from "utils/Date";
import { STATUS_CODE } from "common/Constants";
import { CALL_API } from "common/API";
import { includes } from "lodash";
import COLORS from "common/Colors";

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;

const StyledChartContainer = styled.div`
  border: solid 1px #9b9b9b;
  border-radius: 3px;
  margin-bottom: 25px;
`;

const StyledTable = styled(Table)`
  .ant-table-content {
    overflow-x: auto;
    min-height: 450px;
    max-height: 450px;
    white-space: nowrap;
  }
  .ant-table-cell {
    padding: 10px;
    font-size: 0.8rem;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
  }
  .non-white {
    background: #f9f9f9;
  }
`;

function DashboardPendingLeaves({ userRoleId, selectedDepartmentId }) {
  const [pendingApprovals, setPendingApprovals] = useState([]);
  const [displayPendingApprovalLoader, togglePendingApprovalLoader] = useState(false);
  const data1 = pendingApprovals.filter((val) => {
    if (pendingApprovals === "") {
      return val;
    } else if (val?.status_name.toLowerCase().includes("wait".toLowerCase())) {
      return val;
    } else if (
      val?.status_name.toLowerCase().includes("Approved".toLowerCase())
    ) {
      if (
        JSON.stringify(val?.having_cancel_request)
          .toLowerCase()
          .includes("1".toLowerCase())
      ) {
        return val;
      }
    }
  });
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "10%",
    },
    {
      title: "Employee ID",
      dataIndex: "user_id",
      key: "user_id",
      width: "10%",
      align: "center",
    },
    {
      title: "Department",
      dataIndex: "department_name",
      key: "department_name",
      width: "10%",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      width: "20%",
      render: (_, record) => {
        if (record.new_start_date === null) {
          const dateFormat = moment(record.start_date).format("DD MMM YYYY");
          return `${dateFormat}`;
        } else {
          const dateFormat = moment(record.new_start_date).format(
            "DD MMM YYYY"
          );
          return `${dateFormat}`;
        }
      },
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      width: "20%",
      render: (_, record) => {
        if (record.new_end_date === null) {
          const dateFormat = moment(record.end_date).format("DD MMM YYYY");
          return `${dateFormat}`;
        } else {
          const dateFormat = moment(record.new_end_date).format("DD MMM YYYY");
          return `${dateFormat}`;
        }
      },
    },
    {
      title: "Leave Type",
      dataIndex: "leave_category_name",
      key: "leave_category_name",
      width: "20%",
    },
    {
      title: "Amended Leave",
      dataIndex: "having_cancel_request",
      key: "having_cancel_request",
      render: (having_cancel_request) => {
        if (having_cancel_request === 1) {
          return "Yes";
        } else {
          return "No";
        }
      },
    },
  ];

  const getPendingLeaves = async () => {
    const initialdate = getinitialdate();
    const startDate = getKenyanDateTime();
    const endDate = addDaysinDate(startDate, 365);
    togglePendingApprovalLoader(true);
    const { code, leaves = [] } = await CALL_API("leave-list", "post", {
      status_id: "",
      department_id:
        userRoleId === 1 || userRoleId === 6 ? [""] : selectedDepartmentId,
      selected_role_id: userRoleId,
      start_date: getMySqlDate(initialdate),
      end_date: getMySqlDate(endDate),
    });
    let tempList = [];
    leaves.map((list) => {
      // First parameter is needed
      Object.entries(list).forEach(([key, value]) => {
        value.map((leavelist) => {
          tempList.push(leavelist);
        });
      });
    });
    togglePendingApprovalLoader(false);
    if (includes([STATUS_CODE.SUCCESS, STATUS_CODE.RECORD_NOT_FOUND], code)) {
      setPendingApprovals(tempList);
    }
  };

  useEffect(() => {
    if (
      (userRoleId !== 1 && selectedDepartmentId !== 0) ||
      userRoleId === 1 ||
      userRoleId === 6
    ) {
      getPendingLeaves();
    }
  }, [userRoleId]);

  useEffect(() => {
    if (userRoleId !== 1 || userRoleId === 6) {
      getPendingLeaves();
    }
  }, [selectedDepartmentId]);

  return (
    <>
      <StyledTitle>
        {userRoleId === 1 || userRoleId === 6
          ? "OVERALL WAITING FOR APPROVAL LIST"
          : "LEAVE APPLICATIONS WAITING FOR APPROVAL"}
      </StyledTitle>
      <StyledChartContainer>
        <Spin spinning={displayPendingApprovalLoader}>
          <StyledTable
            dataSource={data1}
            columns={columns}
            // first parameter is needed
            rowClassName={(record, index) =>
              index % 2 === 0 ? "" : "non-white"
            }
            rowKey="user_leave_id"
            pagination={false}
          />
        </Spin>
      </StyledChartContainer>
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
});

export default connect(mapStateToProps, null)(DashboardPendingLeaves);

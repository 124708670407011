import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Select } from 'antd';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import Button from 'atoms/Button';
import { NON_EMPTY_REGEX } from 'common/Constants';
import { toggleProcessingModal, toggleSuccessModal, setAllDepartmentList } from 'redux/actions';
import { getLocations } from 'redux/selectors';
import { bindActionCreators  } from 'redux';
import { CALL_API } from 'common/API';
import { concat } from 'lodash';
import { STATUS_CODE } from 'common/Constants';

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .ant-modal-title {
    font-weight: 600;
    text-align: center;
    font-size: 24px;
    color: #1B1B1B;
  }
  .anticon {
    color: #000000;
    width: 14px;
    height: 14px;
  }
  .ant-modal-close{
    margin-top: 14px;
  }
  .ant-modal-body {
    padding-top: 0px;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-form-item-label {
    font-weight: 500;
  }
  .ant-picker, .ant-input-number {
    width: 100%;
  }
  .ant-btn-text:focus, .ant-btn-text:hover{
    background: #5B86E5;
    color: #FFFFFF;
}
`;

const StyledButton = styled(Button)`
    background: #5B86E5;
    border-radius: 5px;
    color: #FFFFFF;
    width: 100px;
`;

function EditDepartment({
  isModalVisible,
  closeModal,
  getDepartmentList,
  DepartmentDetails,
  locationList,
  saveAllDepartmentList
}) {

  const [ showLoader, toggleLoader ] = useState(false);
  const [ locationId, setLocationId ] = useState(0);
  const [form] = Form.useForm();
  const { TextArea } = Input;

  useEffect(() => {
    const {
      department_id = '',
      DepartmentName = '',
      departmentDescription = '',
      locationName = '',
    } = DepartmentDetails;
      form.setFieldsValue({
        'department_id': department_id,
        'department_name': DepartmentName,
        'description' : departmentDescription,
        // 'locationId' : locationName
      });
  }, [ DepartmentDetails ]);

  const fetchMasterData = async (fetch_master_data) => {
    const {
      code,
      departmentList,
    } = await CALL_API('master-data', 'post', {
      fetch_master_data
    });
    if (code === STATUS_CODE.SUCCESS) {
      if (fetch_master_data) {
        saveAllDepartmentList(departmentList);
      }
   }
  }

  const onFinish = async (values) => {
    toggleLoader(true);
    const {
      department_id,
      department_name,
      locationId,
      description
    } = values;
    await CALL_API('admin-department', 'patch', {
      'department_id': department_id,
      'department_name': department_name,
      'department_description': description,
      'location_id': locationId,
    });
    toggleLoader(false);
    closeModal(true);
    getDepartmentList();
  }

  return (
    <>
      <StyledModal
        title='Edit Department'
        visible={isModalVisible}
        onCancel={() => closeModal(false)}
        width={600}
        footer={[]}
      >
        <Form
          form={form}
          name='EditDepartment'
          layout='vertical'
          onFinish={onFinish}
          initialValues={{
            locationId: parseInt(DepartmentDetails.locationId),
          }}
        >
          <Form.Item
            label='Department ID'
            name='department_id'
          >
            <Input placeholder='Department ID' disabled/>
          </Form.Item>

          <Form.Item
            label='Department Name'
            name='department_name'
            rules={[
              {
                required: true,
                pattern: NON_EMPTY_REGEX,
                message: 'Please enter department name',
              },
            ]}
          >
            <Input placeholder='Department name'/>
          </Form.Item>

          <Form.Item
            label='Location Name'
            name='locationId'
            rules={[
              {
                required: true,
                pattern: NON_EMPTY_REGEX,
                message: 'Please select location name',
              },
            ]}
          >
            <Select
              placeholder="Select Location"
              options={concat({
                location_id: '',
                location_name: 'Select Location'
              },locationList)}
              fieldNames={{
                label: 'location_name',
                value: 'location_id'
              }}
              value={locationId}
              onChange={val => setLocationId(val)}
            />
          </Form.Item>

          <Form.Item
            label='Description'
            name='description'
            rules={[
              {
                required: true,
                pattern: NON_EMPTY_REGEX,
                message: 'Please enter task description!',
              },
            ]}
          >
            <TextArea rows={4} placeholder='Type something..' maxLength={500} />
          </Form.Item>
          <div className='align-center'>
            <StyledButton loading={showLoader} type='primary' htmlType='submit'>
            { showLoader ? 'Update' : 'Update' }
            </StyledButton>
          </div>
        </Form>
      </StyledModal>
    </>
  );}

  const mapStateToProps = state => ({
    locationList: getLocations(state)
  });

  const mapDispatchToProps = dispatch => bindActionCreators(
    {
      updateProcessingModal: toggleProcessingModal,
      updateSuccessModal: toggleSuccessModal,
      saveAllDepartmentList: setAllDepartmentList,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps, null)(EditDepartment);
import React, { useState, useEffect } from "react";
import { Row, Col, Table, Spin, Select, DatePicker, Space, Form } from "antd";
import Button from "atoms/Button";
import moment from "moment";
import styled from "@emotion/styled";
import { utils, writeFileXLSX } from "xlsx";
// Redux
import { connect } from "react-redux";
import {
  getUserRoleId,
  getAllDepartmentList,
  getSelectedDepartmentId,
  getShiftTimeMaster,
  getUserList,
} from "redux/selectors";
import { CALL_API } from "common/API";
import {
  MONTH_LIST,
  REPORT_DURATION_TYPES,
  STATUS_CODE,
} from "common/Constants";
import {
  addDaysinDate,
  dateInDetail,
  getKenyanDateTime,
  getMonthDateRange,
  getMySqlDate,
  momentDate,
  addMonthinDate,
} from "utils/Date";
import COLORS from "common/Colors";
import { concat, find, get, includes, orderBy } from "lodash";

const StyledTable = styled(Table)`
  padding-top: 20px;
  .ant-table-content {
    overflow-x: auto;
    max-height: 500px;
    white-space: nowrap;
  }
  table {
    width: 100% !important;
  }
  .ant-table-cell {
    padding: 20px;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
  }
  .non-white {
    background: #f9f9f9;
  }
`;

const StyledApprovalSearchForm = styled(Form)`
  .ant-form-item-with-help {
    margin-bottom: 0px;
    .ant-form-item-explain-connected {
      display: none;
    }
  }
  .ant-form-item {
    display: inline-block;
  }
  .outer-form {
    margin-right: 0px;
  }
  .ant-select-selector {
    max-width: 180px;
    min-width: 180px;
    margin-bottom: 10px;
    width: 180px;
  }
`;
const StyledButton = styled(Button)`
  width: 120px;
  border: 1px solid ${COLORS.PRIMARY};
  color: ${COLORS.PRIMARY};
  width: initial;
  background: transparent;
`;

function Approval({ userRoleId, allDepartmentList, selectedDepartmentId }) {
  const [displayTableLoader, setDisplayTableLoader] = useState(false);
  const [attendanceList, setAttendanceList] = useState([]);
  const [form] = Form.useForm();

  const filterList = [];
  const [monthList, setMonthList] = useState([]);

  const curr_month = moment().month() + 1;

  const list = () => {
    let month_list = [];
    for (let i = 0; i < curr_month; i++) {
      month_list.push(MONTH_LIST[i]);
    }
    setMonthList(month_list);
  };

  const STAFF_LIST_ALL = attendanceList.map((val) => {
    return { user_id: val.user_id, name: val.name };
  });

  for (let i = 0; i < STAFF_LIST_ALL.length; i++) {
    filterList.push(STAFF_LIST_ALL[i]);
  }

  const STAFF_LIST = [...new Set(filterList.map((a) => JSON.stringify(a)))].map(
    (a) => JSON.parse(a)
  );

  const filterattentancereport = attendanceList.filter((val) => {
    if (form.getFieldValue("staff_name") === "") {
      return val;
    } else if (val?.user_id === form.getFieldValue("staff_name")) {
      return val;
    }
  });

  const columns = [
    {
      title: "Employee ID",
      dataIndex: "user_id",
      key: "user_id",
      width: "7%",
      render: (user_id) => {
        if (user_id !== ("" || null || undefined)) {
          return user_id;
        } else {
          return "-";
        }
      },
    },
    {
      title: "Employee Name",
      dataIndex: "name",
      key: "name",
      width: "10%",
      render: (name) => {
        if (name !== ("" || null || undefined)) {
          return name;
        } else {
          return "-";
        }
      },
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      width: "10%",
      render: (department) => {
        if (department !== ("" || null || undefined)) {
          return department;
        } else {
          return "-";
        }
      },
    },
    {
      title: "Checkin Station",
      dataIndex: "location_name",
      key: "location_name",
      width: "10%",
      render: (location_name) => {
        if (location_name !== ("" || null || undefined)) {
          return location_name;
        } else {
          return "-";
        }
      },
    },
    {
      title: "Check-in date",
      dataIndex: "date",
      key: "date",
      width: "7%",
      render: (date) => {
        if (date === ("" || null || undefined)) return "-";
        const dateFormat = moment(date).format("DD MMM YYYY");
        return `${dateFormat}`;
      },
    },
    {
      title: "Check-in day",
      dataIndex: "date",
      key: "date",
      width: "7%",
      render: (date) => {
        if (date === ("" || null || undefined)) return "-";
        const getdayAlone = moment(date).format("dddd");
        return `${getdayAlone}`;
      },
    },
    {
      title: "Check-in time",
      dataIndex: "start_datetime",
      key: "start_datetime",
      width: "5%",
      render: (start_datetime) => {
        if (start_datetime === "") return "-";
        const { time24 } = dateInDetail(start_datetime);
        return `${time24}`;
      },
    },
    {
      title: "Check-in Coordinates",
      dataIndex: "start_lat_long",
      key: "login_latitude",
      width: "10%",
      render: (_, record) => {
        const url1 =
          "http://maps.google.com/maps/?q=" +
          record.login_latitude +
          "," +
          record.login_longtitude +
          "";
        return (
          <div>
            {record.login_latitude !== ("" || null || undefined) &&
            record.login_longtitude !== ("" || null || undefined) ? (
              <>
                <div>
                  <a href={url1} target="_blank">
                    {record.login_latitude},{record.login_longtitude}
                  </a>
                </div>
              </>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Checkout Station",
      dataIndex: "logout_location_name",
      key: "logout_location_name",
      width: "10%",
      render: (logout_location_name) => {
        if (logout_location_name !== null) {
          return logout_location_name;
        } else {
          return "-";
        }
      },
    },
    {
      title: "Check-out date",
      dataIndex: "end_datetime",
      key: "end_datetime",
      width: "7%",
      render: (end_datetime) => {
        if (end_datetime === null) return "-";
        const dateFormat = moment(end_datetime).format("DD MMM YYYY");
        return `${dateFormat}`;
      },
    },
    {
      title: "Check-out day",
      dataIndex: "end_datetime",
      key: "end_datetime",
      width: "7%",
      render: (end_datetime) => {
        if (end_datetime === null) return "-";
        const getdayAlone = moment(end_datetime).format("dddd");
        return `${getdayAlone}`;
      },
    },
    {
      title: "Check-out time",
      dataIndex: "end_datetime",
      key: "end_datetime",
      width: "5%",
      render: (end_datetime) => {
        if (end_datetime !== null) {
          const { time24 } = dateInDetail(end_datetime);
          return `${time24}`;
        } else {
          return "-";
        }
      },
    },
    {
      title: "Check-out Coordinates",
      dataIndex: "end_lat_long",
      key: "logout_latitude",
      width: "10%",
      render: (_, record) => {
        const url =
          "http://maps.google.com/maps/?q=" +
          record?.logout_latitude +
          "," +
          record?.logout_longtitude +
          "";
        return (
          <div>
            {record?.logout_latitude !== null &&
            record?.logout_longtitude !== null ? (
              <>
                <div>
                  <a href={url} target="_blank">
                    {record?.logout_latitude},{record?.logout_longtitude}
                  </a>
                </div>
              </>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Duration (Hours)",
      dataIndex: "duration",
      key: "duration",
      width: "7%",
    },
    {
      title: "Late time (Hours)",
      dataIndex: "over_time",
      key: "over_time",
      width: "7%",
    },
  ];

  useEffect(() => {
    getAttendanceReport();
  }, [
    selectedDepartmentId,
    userRoleId,
    form.getFieldValue("duration_type"),
    form.getFieldValue("start_date"),
    form.getFieldValue("end_date"),
    form.getFieldValue("month"),
    form.getFieldValue("department"),
  ]);

  // Function To generate report
  const getAttendanceReport = async () => {
    list();
    return new Promise(async () => {
      setAttendanceList([]);
      const { start_date, end_date, duration_type, month, department } =
        form.getFieldsValue();
      setDisplayTableLoader(true);
      const { startDateOfMonth, lastDateOfMonth } = getMonthDateRange(
        dateInDetail(getKenyanDateTime()).year,
        get(find(MONTH_LIST, { value: month }), "index", 0)
      );
      const { code, attendanceList = [] } = await CALL_API(
        "attendance-report",
        "post",
        {
          department:
            userRoleId === 1 || userRoleId === 6
              ? [department]
              : selectedDepartmentId,
          start_date: getMySqlDate(
            duration_type === 2 ? startDateOfMonth : start_date
          ),
          end_date: getMySqlDate(
            duration_type === 2 ? lastDateOfMonth : end_date
          ),
        }
      );
      setDisplayTableLoader(false);
      if (includes([STATUS_CODE.SUCCESS, STATUS_CODE.RECORD_NOT_FOUND], code)) {
        let recordList = [];
        attendanceList.map((list) => {
           // note: First parameter is needed
          Object.entries(list).forEach(([key, value]) => {
            recordList = concat(recordList, value);
          });
        });
        setAttendanceList(orderBy(recordList, ["date"], ["desc"]));
      }
    });
  };

  // Function for Date validation
  const validateDateRange = (date) => {
    const endDate = moment(form.getFieldValue("end_date"));
    if (endDate < date) {
      form.setFieldsValue({ end_date: date });
    }
  };

  // Function to Export table
  const xport = React.useCallback(() => {
    const elt = document.getElementById("Table");
    const wb = utils.table_to_book(elt);
    writeFileXLSX(wb, "attendance-report.xlsx");
  });

  const paginationOptions = {
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    pageSize: 20,
    showSizeChanger: false,
  };

  const xportTableStyle = { display: "none" }

  return (
    <>
      <Row className="page-title">
        <Col xs={24} sm={19} md={19} className="page-title-head">
          <Space>
            <StyledApprovalSearchForm
              form={form}
              name="approval_form"
              layout="inline"
              initialValues={{
                staff_name: "",
                duration_type: 2,
                month: dateInDetail(getKenyanDateTime()).monthName,
                start_date: momentDate(addMonthinDate(getKenyanDateTime(), -1)),
                end_date: momentDate(addDaysinDate(getKenyanDateTime())),
                department: "",
              }}
              onFinish={getAttendanceReport}
            >
              <Form.Item
                name="duration_type"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  placeholder="Select..."
                  options={REPORT_DURATION_TYPES}
                />
              </Form.Item>

              <Form.Item className="outer-form" shouldUpdate>
                {({ getFieldValue }) => {
                  const duration_type = getFieldValue("duration_type");
                  return duration_type === 1 ? (
                    <>
                      <Form.Item
                        name="start_date"
                        rules={[
                          () => ({
                            // note: First parameter is needed
                            validator(rule, value) {
                              const date = moment(value);
                              return date.isValid()
                                ? Promise.resolve()
                                : Promise.reject();
                            },
                          }),
                        ]}
                      >
                        <DatePicker
                          placeholder="Start date"
                          onChange={validateDateRange}
                          allowClear={false}
                        />
                      </Form.Item>

                      <Form.Item
                        name="end_date"
                        rules={[
                          () => ({
                            // note: First parameter is needed
                            validator(rule, value) {
                              const date = moment(value);
                              return date.isValid()
                                ? Promise.resolve()
                                : Promise.reject();
                            },
                          }),
                        ]}
                      >
                        <DatePicker
                          placeholder="End date"
                          onChange={validateDateRange}
                          allowClear={false}
                        />
                      </Form.Item>
                    </>
                  ) : (
                    <>
                      <Form.Item
                        name="month"
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                        ]}
                      >
                        <Select placeholder="Select..." options={monthList} />
                      </Form.Item>
                    </>
                  );
                }}
              </Form.Item>
              <Form.Item
                name="staff_name"
                rules={[
                  {
                    required: false,
                    message: "",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.name ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.name ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.name ?? "").toLowerCase())
                  }
                  placeholder="Select Employee"
                  options={concat(
                    {
                      user_id: "",
                      name: "All Employee",
                    },
                    STAFF_LIST
                  )}
                  fieldNames={{
                    label: "name",
                    value: "user_id",
                  }}
                />
              </Form.Item>
              {(userRoleId === 1 || userRoleId === 6) && (
                <Form.Item name="department">
                  <Select
                    placeholder="Select Department"
                    options={concat(
                      {
                        department_id: "",
                        department_name: "All Departments",
                      },
                      allDepartmentList
                    )}
                    fieldNames={{
                      label: "department_name",
                      value: "department_id",
                    }}
                  />
                </Form.Item>
              )}
            </StyledApprovalSearchForm>
          </Space>
        </Col>
        <Col xs={24} sm={4} md={4} className="align-right">
          <StyledButton
            onClick={xport}
            disabled={filterattentancereport.length === 0}
          >
            Export XLSX file
          </StyledButton>
        </Col>
      </Row>
      <Spin spinning={displayTableLoader}>
        <StyledTable
          dataSource={filterattentancereport}
          columns={columns}
          // Note : First parameter is needed
          rowClassName={(record, index) => (index % 2 === 0 ? "" : "non-white")}
          rowKey="checkin_id"
          pagination={
            filterattentancereport.length > 20 ? paginationOptions : false
          }
        />
        {/* Below table is for Export the data */}
        <Table
          style={xportTableStyle}
          dataSource={filterattentancereport}
          columns={columns}
          // Note : First parameter is needed
          rowClassName={(record, index) => (index % 2 === 0 ? "" : "non-white")}
          rowKey="checkin_id"
          pagination={false}
          id="Table"
        />
      </Spin>
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  userList: getUserList(state),
  allDepartmentList: getAllDepartmentList(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  shiftTimeMaster: getShiftTimeMaster(state),
});

export default connect(mapStateToProps, null)(Approval);

import React from "react";
import { useHistory } from "react-router-dom";
import { Card, Avatar, Tooltip, Badge } from "antd";
// Redux
import { connect } from "react-redux";
import {
  getUserName,
  getUserRoleId,
  getUnreadChatMessageList,
  getUserInfo,
} from "redux/selectors";
import styled from "@emotion/styled";
import { includes, startCase } from "lodash";
import COLORS from "common/Colors";
import Icon from "atoms/Icon";

const StyledCard = styled(Card)`
  min-height: 350px;
  padding-left: 1%;
  text-align: center;
  border-radius: 5px;
  padding: 2%;
  position: relative;
  .threedot {
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
  }
  .ant-card-body {
    padding-bottom: 15px !important;
  }
`;

const StyledAvatar = styled(Avatar)`
  background-color: ${COLORS.PRIMARY};
  vertical-align: middle;
  font-size: 3rem;
  height: 100px;
  text-align: center;
  width: 100px;
  .ant-avatar-string {
    margin-top: 2rem;
  }
`;

const StyleName = styled.h1`
  padding-top: 1rem;
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
  color: #5b86e5;
`;

const StyleEmployeeId = styled.h2`
  padding: 5px;
  font-size: 0.8rem;
  margin-bottom: 0px;
  text-align: left;
`;

const StyleDepartment = styled.h4`
  display:flex;
  gap: 5px;
  text-align: left;
  width: 100%;
  padding: 5px;
  font-size: 0.8rem;
  margin-bottom: 0px;
  b{
    white-space: nowrap;
  }
`;

const StyleEmailId = styled.h3`
  text-align: left;
  padding: 5px;
  font-size: 0.7rem;
  color: #5b86e5;
`;

const StyledViewDocuments = styled.div`
  padding-top: 10px;
  font-weight: bold;
  font-size: 12px;
  color: #5b86e5;
  cursor: pointer;
`;

const StyledBadge = styled(Badge)`
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
  .ant-badge-dot {
    height: 10px;
    width: 10px;
    animation: blinker 1s linear infinite;
  }
`;

function TeamCard({
  id,
  name,
  email_id,
  dept_name,
  userRoleId,
  unreadChatMessageList,
  profile,
  userInfo,
}) {
  let history = useHistory();
  return (
    <StyledCard>
      {id != userInfo?.employer_id && (
        <StyledBadge
          dot={includes(unreadChatMessageList, id)}
          className="threedot"
        >
          <Icon name="chat" onClick={() => history.push(`/chat/${id}`)} />
        </StyledBadge>
      )}
      {profile === null || profile === "" || profile === undefined || profile == "null" ? (
        <StyledAvatar size="default">{startCase(name.charAt(0))}</StyledAvatar>
      ) : (
        <StyledAvatar src={profile} size="default"></StyledAvatar>
      )}
      <StyleName>
        <Tooltip title={name}>{name}</Tooltip>
      </StyleName>
      <StyleEmployeeId>
        <b> Employee ID: </b>
        {id}
      </StyleEmployeeId>
      <StyleDepartment>
          <b> Department : </b>
          <>{dept_name}</>
      </StyleDepartment>
      <StyleEmailId>
        <Tooltip title={email_id}>
          <b> Email Id: </b>
          {email_id}
        </Tooltip>
      </StyleEmailId>
      {(userRoleId === 1 ||
        userRoleId === 3 ||
        userRoleId === 2 ||
        userRoleId === 6) && (
        <StyledViewDocuments onClick={() => history.push(`/documents/${id}`)}>
          View Documents
        </StyledViewDocuments>
      )}
    </StyledCard>
  );
}

const mapStateToProps = (state) => ({
  userName: getUserName(state),
  userRoleId: getUserRoleId(state),
  unreadChatMessageList: getUnreadChatMessageList(state),
  userInfo: getUserInfo(state),
});

export default connect(mapStateToProps, null)(TeamCard);

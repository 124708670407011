import React, { useEffect, useRef, useState } from "react";
import { Avatar as AntAvatar, Tooltip } from "antd";
import { useHistory } from "react-router-dom";
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getUserList,
  getUserRoleId,
  getAllDepartmentList,
  getDepartmentUserList,
  getSelectedDepartmentId,
  getUserName,
  getChatMessageList,
  getChatSelectedStaffId,
  getChatMessageQueue,
  getUnreadChatMessageList,
  getUserInfo,
  getDepartmentList,
} from "redux/selectors";
import {
  setChatMessageList,
  setChatSelectedStaffId,
  setChatMessageQueue,
  setUnreadMessageIndicator,
} from "redux/actions";
import {
  concat,
  filter,
  find,
  get,
  includes,
  lowerCase,
  map,
  size,
  startCase,
  uniqBy,
  result,
} from "lodash";
import { useParams } from "react-router-dom";
import {
  Avatar,
  ChatContainer,
  Conversation,
  ConversationHeader,
  ConversationList,
  MainContainer,
  Message,
  MessageInput,
  MessageList,
  Search,
  Sidebar,
} from "@chatscope/chat-ui-kit-react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import COLORS from "common/Colors";
import styled from "styled-components";
import EventBus from "utils/EventBus";
import { CALL_API } from "common/API";

const StyledChatContainer = styled.div`
  .cs-conversation__name {
    padding-top: 8px;
  }
  .cs-search {
    margin: 1em !important;
    background: #f2f2f2;
    input {
      background: #f2f2f2;
    }
  }
  .cs-sidebar {
    box-shadow: inset 2px 0px 5px #b9babc;
  }
  .cs-conversation {
    border-top: solid 1px #d1dbe3;
    margin-left: 3px;
  }
  .cs-avatar {
    padding-top: 5px;
  }
  .cs-message-input {
    padding: 10px;
  }
  .cs-message--outgoing .cs-message__content {
    background-color: #ececec;
  }
  .cs-message:first-child {
    margin-top: 10px;
  }
  .cs-conversation__content {
    display: block !important;
  }
`;

function Chat({
  userName,
  userList,
  userRoleId,
  selectedDepartmentId,
  saveChatMessageList,
  saveChatSelectedStaffId,
  chatMessageList,
  chatSelectedStaffId,
  chatMessageQueue,
  saveChatMessageQueue,
  newMessageIndicatorList,
  saveUnreadMessageIndicator,
  userInfo,
}) {
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [employeeNameFilter, setEmployeeNameFilter] = useState("");
  const [messageInputValue, setMessageInputValue] = useState("");
  const [height, setHeight] = useState(500);
  const [selectedStaffName, setSelectedStaffName] = useState("");
  const [selectedStaffProfile, setSelectedStaffProfile] = useState("");
  const [messageListLoading, toggleMessageListLoading] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [sidebarStyle, setSidebarStyle] = useState({});
  const [chatContainerStyle, setChatContainerStyle] = useState({});

  const { id: staffId } = useParams();
  const inputRef = useRef();
  let history = useHistory();

  useEffect(() => {
    if (userRoleId === 1 || userRoleId === 6) {
      setSelectedDepartment("");
    } else {
      setSelectedDepartment(selectedDepartmentId);
    }
  }, [selectedDepartmentId]);

  useEffect(() => {
    const notifications = filter(
      chatMessageQueue,
      ({ action }) => action === "NOTIFICATION"
    );
    if (size(notifications)) {
      for (let ind = 0, len = size(notifications); ind < len; ind++) {
        handleReceivedMessage(notifications[ind]);
      }
      saveChatMessageQueue(
        filter(chatMessageQueue, ({ action }) => action !== "NOTIFICATION")
      );
    }
  }, [chatMessageQueue]);

  const handleReceivedMessage = (payload) => {
    if (payload.senderId === parseInt(chatSelectedStaffId)) {
      const msgList = Array.from(chatMessageList);
      msgList.push({
        message: payload.message,
        sentTime: "",
        sender: payload.sender,
        direction: "incoming",
        position: "single",
      });
      saveChatMessageList(msgList);
    } else {
      if (!includes(newMessageIndicatorList, payload.senderId)) {
        saveUnreadMessageIndicator(
          concat(newMessageIndicatorList, payload.senderId)
        );
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      inputRef.current && inputRef.current.focus();
    }, 2000);
    setHeight(
      window.innerHeight - document.querySelector(".ant-layout-header").getBoundingClientRect().height
    );
    saveChatSelectedStaffId(staffId);
    setSelectedStaffName(
      get(find(userList, { user_id: parseInt(staffId) }), "name")
    );
    getMessageList(staffId);
  }, []);

  const getStaffList = () => {
    const allStaffList = userList;
    var staffList;
    if (userRoleId === 1 || userRoleId === 6) {
      staffList =
        selectedDepartment !== ""
          ? filter(allStaffList, { department_id: selectedDepartment })
          : allStaffList;
      staffList = filter(
        staffList,
        (v) => v.user_id !== get(userInfo, "employer_id", "")
      );
      return uniqBy(
        employeeNameFilter !== ""
          ? filter(staffList, (staff) => {
              return (
                lowerCase(staff.name).indexOf(lowerCase(employeeNameFilter)) >
                -1
              );
            })
          : staffList,
        "user_id"
      );
    } else {
      if (userRoleId === 4) {
        staffList = filter(
          allStaffList,
          (v) => get(userInfo, "staff_department_id", "") == v.department_id
        );
        staffList = filter(
          staffList,
          (v) => v.user_id !== get(userInfo, "employer_id", "")
        );
      } else {
        staffList =
          selectedDepartment !== []
            ? filter(
                allStaffList,
                (v) =>
                  includes(selectedDepartment, v.department_id) &&
                  v.user_id !== get(userInfo, "employer_id", "")
              )
            : allStaffList;
      }
      if (userRoleId !== 1 || userRoleId !== 6) {
        staffList = concat(
          staffList,
          filter(userList, (user) => user.role_id === 1 || user.role_id === 6)
        );
      }

      return uniqBy(
        employeeNameFilter !== ""
          ? filter(staffList, (staff) => {
              return (
                lowerCase(staff.name).indexOf(lowerCase(employeeNameFilter)) >
                -1
              );
            })
          : staffList,
        "user_id"
      );
    }
  };


  const staffSelected = (staff) => {
    if (sidebarVisible) {
      setSidebarVisible(false);
    }
    setSelectedStaffName(staff.name);
    setSelectedStaffProfile(staff.profile_url);
    saveChatSelectedStaffId(staff.user_id);
    getMessageList(staff.user_id);
    history.push(`/chat/${staff.user_id}`);
  };

  const getMessageList = async (staff_Id) => {
    markChatConversationAsRead(staff_Id);
    setSelectedStaffProfile(
      result(find(userList, { user_id: parseInt(staff_Id) }), "profile_url")
    );
    toggleMessageListLoading(true);
    saveChatMessageList([]);
    if (includes(newMessageIndicatorList, parseInt(staff_Id))) {
      saveUnreadMessageIndicator(
        filter(newMessageIndicatorList, (sid) => sid !== parseInt(staff_Id))
      );
    }
    const { messages, user_id } = await CALL_API("chat-message-list", "post", {
      senderId: staff_Id,
    });
    if (size(messages) > 0) {
      const msgList = [];
      map(messages, ({ datetime, message, sender_user_id, sender_name }) => {
        msgList.push({
          message,
          sentTime: datetime,
          sender: sender_name,
          direction: sender_user_id === user_id ? "outgoing" : "incoming",
          position: "single",
          profile: result(
            find(userList, { user_id: sender_user_id }),
            "profile_url"
          ),
        });
      });
      saveChatMessageList(msgList);
    }
    toggleMessageListLoading(false);
  };

  const markChatConversationAsRead = async (staff_Id) => {
    await CALL_API("mark-chat-message-as-read", "post", {
      staffId: staff_Id,
    });
  };

  const sendMessage = () => {
    const message = Array.from(chatMessageList);
    message.push({
      message: messageInputValue,
      sentTime: "",
      sender: userName,
      direction: "outgoing",
      position: "single",
    });
    EventBus.$dispatch("sendMessage", {
      action: "NOTIFICATION",
      payload: {
        message: messageInputValue,
        receiverUserId: chatSelectedStaffId,
      },
    });
    saveChatMessageList(message);
    setMessageInputValue("");
  };

  const checkForNewMessage = (staff_Id) =>
    includes(newMessageIndicatorList, staff_Id);

  const handleBackClick = () => setSidebarVisible(!sidebarVisible);

  useEffect(() => {
    if (sidebarVisible) {
      setSidebarStyle({
        display: "flex",
        flexBasis: "auto",
        width: "100%",
        maxWidth: "100%",
      });

      setChatContainerStyle({
        display: "none",
      });
    } else {
      setSidebarStyle({});
      setChatContainerStyle({});
    }
  }, [
    sidebarVisible,
    setSidebarVisible,
    setSidebarStyle,
    setChatContainerStyle,
  ]);

  const containerStyle = { height: `${height}px`, position: "relative" };
  const avatarStyle = {
    backgroundColor: COLORS.PRIMARY,
    verticalAlign: "middle",
  };

  const ConversationHeaderStyle = { display : "inline" }

  return (
    <>
      <StyledChatContainer>
        <div style={containerStyle}>
          <MainContainer responsive>
            <Sidebar position="left" scrollable={false} style={sidebarStyle}>
              <Search
                placeholder="Search..."
                onChange={(val) => setEmployeeNameFilter(val)}
              />
              <ConversationList>
                {map(getStaffList(), (staff, index) => (
                  <Conversation
                    name={get(staff, "name", "")}
                    key={index}
                    unreadDot={checkForNewMessage(get(staff, "user_id", ""))}
                    onClick={() => staffSelected(staff)}
                  >
                    <Avatar name={staff.name} status="available">
                      <Tooltip title={staff.name}>
                        {staff.profile_url === null ||
                        staff.profile_url === "" ||
                        staff.profile_url == "null" ||
                        staff.profile_url == undefined ? (
                          <AntAvatar style={avatarStyle}>
                            {startCase(staff.name.charAt(0))}
                          </AntAvatar>
                        ) : (
                          <AntAvatar src={staff.profile_url}></AntAvatar>
                        )}
                      </Tooltip>
                    </Avatar>
                  </Conversation>
                ))}
              </ConversationList>
            </Sidebar>

            {selectedStaffName && (
              <ChatContainer style={chatContainerStyle}>
                <ConversationHeader>
                  <ConversationHeader.Back
                    style={ConversationHeaderStyle}
                    onClick={handleBackClick}
                  />
                  <Avatar name={selectedStaffName}>
                    <Tooltip title={selectedStaffName}>
                      {selectedStaffProfile === null ||
                      selectedStaffProfile === "" ||
                      selectedStaffProfile == "null" ||
                      selectedStaffProfile == undefined ? (
                        <AntAvatar style={avatarStyle}>
                          {startCase(selectedStaffName.charAt(0))}
                        </AntAvatar>
                      ) : (
                        <AntAvatar src={selectedStaffProfile} />
                      )}
                    </Tooltip>
                  </Avatar>
                  <ConversationHeader.Content
                    userName={selectedStaffName}
                    info=""
                  />
                </ConversationHeader>
                <MessageList loading={messageListLoading}>
                  {map(chatMessageList, (message, index) => (
                    <Message model={message} key={index}>
                      <Avatar name={message.sender}>
                        <Tooltip title={message.sender}>
                          {message.profile === null ||
                          message.profile === "" ||
                          message.profile == "null" ||
                          message.profile == undefined ? (
                            <AntAvatar style={avatarStyle}>
                              {startCase(message.sender.charAt(0))}
                            </AntAvatar>
                          ) : (
                            <AntAvatar src={message.profile} />
                          )}
                        </Tooltip>
                      </Avatar>
                    </Message>
                  ))}
                </MessageList>
                <MessageInput
                  attachButton={false}
                  placeholder="Type message here"
                  value={messageInputValue}
                  onChange={(val) => setMessageInputValue(val)}
                  onSend={sendMessage}
                  ref={inputRef}
                />
              </ChatContainer>
            )}
          </MainContainer>
        </div>
      </StyledChatContainer>
    </>
  );
}

const mapStateToProps = (state) => ({
  userName: getUserName(state),
  userList: getUserList(state),
  userRoleId: getUserRoleId(state),
  allDepartmentList: getAllDepartmentList(state),
  departmentUserList: getDepartmentUserList(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  chatMessageList: getChatMessageList(state),
  chatSelectedStaffId: getChatSelectedStaffId(state),
  chatMessageQueue: getChatMessageQueue(state),
  newMessageIndicatorList: getUnreadChatMessageList(state),
  userInfo: getUserInfo(state),
  departmentList: getDepartmentList(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveChatMessageList: setChatMessageList,
      saveChatSelectedStaffId: setChatSelectedStaffId,
      saveChatMessageQueue: setChatMessageQueue,
      saveUnreadMessageIndicator: setUnreadMessageIndicator,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Chat);

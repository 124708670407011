import React, { useState } from 'react';
import { Row, Col, Card, Popover, Badge, Button, Modal, message, Divider } from 'antd';
import {
  EditOutlined
} from '@ant-design/icons';

import styled from '@emotion/styled';
import COLORS from 'common/Colors';
import { dateInDetail } from 'utils/Date';
import { STATUS_CODE } from 'common/Constants';
import { CALL_API } from 'common/API';

import {
  getUserRoleId,
} from 'redux/selectors';
// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleProcessingModal, toggleSuccessModal } from 'redux/actions';
import RejectReasonModal from './RejectReasonModal';

const StyledRowContainer = styled(Card)`
  ${({ color }) => color && `background: ${color}`};
  border-radius: 3px;
  .ant-card-body {
    padding: 2px 5px;
  }
  svg{
    height: 15px !important;
    vertical-align: middle;
    fill: ${COLORS.WHITE} !important;
  }
  .staff-name {
    padding-left: 3px;
    font-size: 0.7rem;
    color: ${COLORS.WHITE};
    font-weight: bold;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-top: 1px;
  }
`;

const StyledButton = styled(Button)`
  border: none;
  width: 120px;
  margin: 10px;
  border-radius: 5px;
  // color: ${COLORS.PRIMARY};
  // font-weight: bold;
  width: initial;
  // font-size: 0.9rem;
  // :hover {
  //   color: ${COLORS.PRIMARY};
  //   font-weight: bold;
  //   border: none;
  // }
`;

const StyledLeaveDetailsPopover = styled.div`
  width: 350px;
  padding: 5px 1rem 1rem;
  .ant-col {
    padding: 3px;
  }
  .ant-col:nth-child(1) {
    font-weight: bold;
  }
`;

const StyledLeaveDetailsPopoverFooter = styled.div`
  text-align: right;
  margin-right: -15px;
  .cancel-request {
    margin-right: 10px;
    padding: 0px 10px;
    background: #dbdbdb;
    margin-bottom: 10px;
  }
`

const StyledDivider = styled(Divider)`
  margin: 5px;
`;

const StyledAmmendmentTitle = styled.div`
  padding: 10px 0px 7px 0px;
`

function StaffLeaveIndicatorInCalendar({
  leaveDetails,
  isStaff,
  children,
  reloadList,
  displayLeaveDetail = true,
  updateProcessingModal,
  updateSuccessModal,
  openEditLeaveModal,
  EditLeaveModal,
  userRoleId
}) {
  const [displayDeclineReasonModal, toggleDeclineReasonModalVisibility] = useState(false);
  const {
    user_leave_id,
    status_name,
    status_id,
    start_date,
    end_date,
    reason,
    name,
    number_of_days,
    leave_category_name,
    having_cancel_request,
    new_start_date,
    new_end_date,
    new_number_of_days
  } = leaveDetails;
  const { date_th: start_date_th, shortMonthName: start_month, year: start_year } = dateInDetail(start_date);
  const { date_th: end_date_th, shortMonthName: end_month, year: end_year } = dateInDetail(end_date);

  const { date_th: new_start_date_th, shortMonthName: new_start_month, year: new_start_year } = dateInDetail(new_start_date);
  const { date_th: new_end_date_th, shortMonthName: new_end_month, year: new_end_year } = dateInDetail(new_end_date);
  const [open, setOpen] = useState(false);
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
  const hide = () => {
    setOpen(false);
  };

  const approveRejectCancelLeave = async (statusId, declined_reason = '', is_leave_cancel_request = false) => {
    return new Promise(async (resolve) => {
      const {
        code,
      } = await CALL_API('approve-reject-cancel-leave', 'post', {
        user_leave_id,
        status_id: statusId,
        declined_reason,
        is_cancel_request: is_leave_cancel_request,
      });
      updateProcessingModal(false);
      if (code === STATUS_CODE.SUCCESS) {
        const cancelRequestMessage = `Leave amendment request has been ${statusId === 5 ? 'accepted' : 'declined'}.`;
        updateSuccessModal({
          visibility: true,
          message: is_leave_cancel_request ? cancelRequestMessage : `Leave has been ${statusId === 5 ? 'approved' : (statusId === 6 ? 'declined' : 'cancelled')} successfully.`
        });
        reloadList();
      } else {
        message.error(`Something went wrong. Please try again.`);
      }
      resolve();
    })
  }

  const confirmOperation = (statusId) => {
    Modal.confirm({
      icon: '',
      content: `Are you sure you want to ${statusId === 5 ? 'approve' : (statusId === 6 ? 'decline' : 'cancel')} leave?`,
      okText: 'YES',
      cancelText: 'No',
      onOk: async () => {
        return new Promise(async (resolve) => {
          await approveRejectCancelLeave(statusId);
          resolve();
        }).catch(() => console.log('Oops errors!'));
      }
    });
  }

  const approveLeaveCancelRequest = (approve) => {
    Modal.confirm({
      icon: '',
      content: `Are you sure you want to ${approve === true ? 'approve' : 'reject'} leave amendment application?`,
      okText: 'YES',
      cancelText: 'No',
      onOk: async () => {
        return new Promise(async (resolve) => {
          await approveRejectCancelLeave(approve ? 5 : 9, '', true);
          resolve();
        }).catch(() => console.log('Oops errors!'));
      }
    });
  }

  const closeDeclineReasonModal = async (reason) => {
    if (reason) await approveRejectCancelLeave(6, reason);
    toggleDeclineReasonModalVisibility(false);
  }

  const content = (
    <>
      <StyledLeaveDetailsPopover >
        <Row>
          <Col span={20}>
            <h5>
              <Badge status="success" size='small' color={status_id === 4 ? COLORS.PENDING_LEAVE : (having_cancel_request === 1 ? COLORS.PENDING_LEAVE : (status_id === 5 ? COLORS.APPROVED_LEAVE : ''))} />
              {name}
            </h5>
          </Col>

          <Col span={4} className='align-right'>
            {
              status_id === 4 && userRoleId === 4 && (
                <EditOutlined
                  style={{ fontSize: '1rem', padding: '10px', paddingRight: '0px', cursor: 'pointer' }}
                  onClick={() => { openEditLeaveModal(leaveDetails); hide() }}
                />
              )
            }
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={8}>From date:</Col>
          <Col span={16}>{start_date_th} {start_month} {start_year}</Col>
        </Row>

        <Row gutter={20}>
          <Col span={8}>To date:</Col>
          <Col span={16}>{end_date_th} {end_month} {end_year}</Col>
        </Row>

        <Row gutter={20}>
          <Col span={8}>No. of days:</Col>
          <Col span={16}>{number_of_days}</Col>
        </Row>

        {
          having_cancel_request === 1 && (
            <>
              <Row gutter={20}>
                <Col span={24}>
                  <StyledDivider />
                  <StyledAmmendmentTitle>Ammendment Details</StyledAmmendmentTitle>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col span={8}>From date:</Col>
                <Col span={16}>{new_start_date_th} {new_start_month} {new_start_year}</Col>
              </Row>

              <Row gutter={20}>
                <Col span={8}>To date:</Col>
                <Col span={16}>{new_end_date_th} {new_end_month} {new_end_year}</Col>
              </Row>

              <Row gutter={20}>
                <Col span={8}>No. of days:</Col>
                <Col span={16}>{new_number_of_days}</Col>
              </Row>

              <Row>
                <Col span={24}>
                  <StyledDivider />
                </Col>
              </Row>
            </>
          )
        }

        <Row gutter={20}>
          <Col span={8}>Status:</Col>
          <Col span={16}>{status_name}</Col>
        </Row>

        <Row gutter={20}>
          <Col span={8}>Leave Type:</Col>
          <Col span={16}>{leave_category_name}</Col>
        </Row>

        <Row gutter={20}>
          <Col span={8}>Description:</Col>
          <Col span={16}>{reason}</Col>
        </Row>

        {/* {
          having_cancel_request && (
            <Row gutter={20}>
              <Col span={8}>Respond to leave cancellation request</Col>
              <Col span={16}>
                <StyledButton type='text' onClick={() => toggleDeclineReasonModalVisibility(true)}>
                  Approve Leave Cancellation
                </StyledButton>
                <StyledButton type='text' onClick={() => toggleDeclineReasonModalVisibility(true)}>
                  Reject Leave Cancellation
                </StyledButton>
              </Col>
            </Row>

          )
        } */}
      </StyledLeaveDetailsPopover>
      <StyledLeaveDetailsPopoverFooter>
        {
          !isStaff && having_cancel_request === 1 && (
            <div className='cancel-request'>
              <span>Respond to leave amendment request</span>
              <StyledButton type='primary' onClick={() => { approveLeaveCancelRequest(true); hide() }}>
                Approve
              </StyledButton>
              <StyledButton type='primary' onClick={() => { approveLeaveCancelRequest(false); hide() }}>
                Reject
              </StyledButton>
            </div>
          )
        }
        {
          !isStaff && status_id === 4 && (
            <StyledButton type='primary' onClick={() => { confirmOperation(5); hide() }}>
              Approve
            </StyledButton>
          )
        }
        {
          !isStaff && having_cancel_request !== 1 &&  (
            <StyledButton type='primary' onClick={() => { toggleDeclineReasonModalVisibility(true); hide() }}>
              Decline
            </StyledButton>
          )
        }
        {
          isStaff && (
            <StyledButton type='primary' disabled={having_cancel_request} onClick={(event) => {
              EditLeaveModal({
                ...leaveDetails,
                isCancel: true
              }); hide()
            }} style={{ color: 'white' }}>
              {having_cancel_request ? 'Amendment Requested' : (status_id === 5 ? 'Amend Leave' : 'Cancel Leave')}
            </StyledButton>
          )
        }
      </StyledLeaveDetailsPopoverFooter>
    </>
  );

  const leaveRow = () => {
    return !isStaff && (
      <StyledRowContainer color={status_id === 4 ? COLORS.PENDING_LEAVE : (having_cancel_request === 1 ? COLORS.PENDING_LEAVE : (status_id === 5 ? COLORS.APPROVED_LEAVE : ''))}>
        <Row>
          <Col span={24} className='staff-name'>
            {name}
          </Col>
        </Row>
      </StyledRowContainer>
    )
  }

  return (
    <>
      {
        displayLeaveDetail && (
          <Popover className='leave-detail-popover' content={content} placement="rightTop" trigger="hover" open={open} onOpenChange={handleOpenChange}>
            {leaveRow()}
            {children}
          </Popover>
        )
      }
      {!displayLeaveDetail && leaveRow()}
      <RejectReasonModal isOpenModal={displayDeclineReasonModal} closeModal={closeDeclineReasonModal} />
    </>
  );
}

const mapStateToProps = state => ({
  userRoleId: getUserRoleId(state),

});
const mapDispatchToProps = dispatch => bindActionCreators(
  {
    updateProcessingModal: toggleProcessingModal,
    updateSuccessModal: toggleSuccessModal,
  },
  dispatch
);

export default connect(mapStateToProps, mapDispatchToProps, null)(StaffLeaveIndicatorInCalendar);
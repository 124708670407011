import React from 'react';
import { Row, Col, Card, Modal, Avatar, Button, Spin, message } from 'antd';
import { useState, useEffect } from 'react';
import COLORS from 'common/Colors';
import styled from 'styled-components';
import Icon from 'atoms/Icon';
// Redux
import { connect } from 'react-redux';
import {
  getUserInfo,
  getNotificationList,
  getUserRoleId
} from 'redux/selectors';
import { get, startCase } from 'lodash';
import { dateInDetail } from 'utils/Date';
import { DEVICE } from 'common/Device';
import ResetPasswordModal from 'organisms/ResetPasswordModal';
import { useHistory } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { clearStore } from 'redux/actions';
import { display } from '@mui/system';
import { Switch } from 'antd';
import { CALL_API } from 'common/API';

const StyledProfileContainer = styled.div`
 /* @media (min-width:768px) and (max-width:1023px)  {
  .profile-row{
    margin-top:15px;
  }}
  @media (min-width:1024px)  {
  .profile-row{
    margin-top:30px;
  }} */
`;
const StyledStaffNameContainer = styled.div`
  text-align: center;
  
  .staff-name {
    font-weight: bold;
    font-size: 1rem;
    padding-top: 10px;
  }
  .location {
    padding-top: 5px;
    svg {
      vertical-align: sub;
      margin-right: 5px;
    }
  }
`;

const StyledButtonContainer = styled.div`
  text-align: center;
  
  @media (max-width:1054px)  {
  .btn{
    font-size:10px;
    // padding-left:10px;
    text-align:center;
  }}
  @media ${DEVICE.tablet} {
    .btn{
      font-size:12px;
    }
}
`;

const StyledAvatar = styled(Avatar)`
  font-size: 5rem;
  height: 100px;
  width: 100px;
  .ant-avatar-string {
    margin-top: 2rem;
  }
`;

const StyledRow = styled(Row)`
  padding-top: 5px;
  padding-bottom: 5px;
`;
const StyleRow = styled(Row)`
  padding:20px
  border-bottom: 2px solid grey;
`;

const ResetPasswordLink = styled.div`
  cursor: pointer;
  color: #5c86e6;
  font-weight: bold;
`;

function MyProfile({
  userInfo,
  notificationList,
  setLoggedIn,
  removeStoreData,
  userRoleId
}) {
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
  const [count, setCount] = useState(notificationList.length);
  const joiningDate = dateInDetail(get(userInfo, 'joining_date', ''));
  console.log(`${joiningDate.date_th} ${joiningDate.monthName} ${joiningDate.year}`)
  let history = useHistory();
  const [active, setActive] = useState(false);
  const [show, setShow] = useState(false);
  const [ showReport, setShowReport ] = useState(false);
  const [ attendanceReport, setAttendanceReport ] = useState(false)
  const [ overtimeReport, setOvertimeReport ] = useState(false)
  const [ earlyCheckoutReport, setEarlyCheckoutReport ] = useState(false)
  const [ lateCheckinReport, setLateCheckinReport ] = useState(false)
  const [ absentReport, setAbsentReport ] = useState(false)
  const [ displayReportLoader, toggleReportLoader ] = useState(false);

  const logoutUser = () => {
    Modal.confirm({
      // title: 'Confirm',
      icon: '',
      content: 'Are you sure you want to logout?',
      okText: 'Logout',
      cancelText: 'Cancel',
      onOk: () => {
        setTimeout(() => {
          window.localStorage.removeItem('Tymeplusjwt');
          window.localStorage.removeItem('login_reminder');
          // setLoggedIn();
          removeStoreData();
          history.push(`/`)
        }, 500);
        setCount(notificationList.length);
      }
    });
  }

  const getPreferenceData = async () => {
    toggleReportLoader(true);
    const response = await CALL_API('user-preference-report', 'get');
    response.map(val => {
      if(val.report_id == 1){
        setAttendanceReport(val.status_name == 'true' ? true : false )
      }
      else if(val.report_id == 2){
        setOvertimeReport(val.status_name == 'true' ? true : false )
      }
      else if(val.report_id == 3){
        setLateCheckinReport(val.status_name == 'true' ? true : false)    
      }
      else if(val.report_id == 4){
        setEarlyCheckoutReport(val.status_name == 'true' ? true : false)
      }
      else if(val.report_id == 5){
        setAbsentReport(val.status_name == 'true' ? true : false)
      }
    })
   
    toggleReportLoader(false);
  }

  const handleChange = async (id, val) => {
    toggleReportLoader(true);
    const response = await CALL_API('user-preference-report', 'patch',{
      user_preference_id : id,
      status_name : val
    });
    message.success('Updated successfully')
    getPreferenceData();
    toggleReportLoader(false);
    // console.log('clicked')
  }
  useEffect(() => {
    getPreferenceData();
  }, []);
  return (
    <>
      <StyledProfileContainer>
        <Row className='profile-row' gutter={24} >

          <Col md={24} sm={24} xs={24} lg={userRoleId == 2 ? 4 : 6} >
            <Card bordered={false} style={{width:'100%', display: 'flex', height:' 100%', alignItems: 'center', justifyContent:'center'}}>
              <StyledStaffNameContainer>
                <StyledAvatar
                  style={{
                    backgroundColor: COLORS.PRIMARY,
                    verticalAlign: 'middle',
                  }}
                  size="default"
                >
                  {startCase(get(userInfo, 'name', '').charAt(0))}
                </StyledAvatar>
                <div className='staff-name'>
                  {get(userInfo, 'name', '')}
                </div>
                {
                  get(userInfo, 'location_name', '') && (
                    <div className='location'>
                      <Icon name='location' />
                      <span>
                        {
                          get(userInfo, 'location_name', '')
                        }
                      </span>
                    </div>
                  )
                }
              </StyledStaffNameContainer>

              {/* <Row>
                <Col offset={2} span={20}>
                  <StyledButtonContainer>
                    <Button className='btn' type="primary" style={{color:'#fff'}} block disabled>Manage my account</Button>
                  </StyledButtonContainer>
                </Col>
              </Row> */}


              {/* <br /><br /><br /><br /> */}
              <Row style={{ display: 'none' }}>
                <Col offset={2} span={20}>
                  <StyledButtonContainer>
                    <Button type="primary" style={{ color: '#fff' }} block disabled>View More</Button>
                  </StyledButtonContainer>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col md={24} sm={24} xs={24} lg={userRoleId == 2 ? 12 : 18}>
            <Card bordered={false} style={{width:'100%', height:' 100%', paddingLeft:'10%', paddingTop:'5%'}}>
              <StyledRow >
                <Col className='my-1' md={8} sm={8} xs={10} lg={8} xl={6}>
                  <b>Name:</b>
                </Col>
                <Col className='my-1' md={16} sm={14} xs={14} lg={16} xl={18}>
                  {
                    get(userInfo, 'name', '')
                  }
                </Col>
              </StyledRow>
              <StyledRow className='my-4'>
                <Col className='my-1' md={8} sm={8} xs={10} lg={8} xl={6}>
                  <b>Username:</b>
                </Col>
                <Col className='my-1' md={16} sm={14} xs={14} lg={16} xl={18}>
                  {
                    get(userInfo, 'username', '')
                  }
                </Col>
              </StyledRow>
              <StyledRow className='my-4'>
                <Col className='my-1' md={8} sm={8} xs={10} lg={8} xl={6}>
                  <b>Email ID:</b>
                </Col>
                <Col className='my-1' md={16} sm={14} xs={14} lg={16} xl={18}>
                  {
                    get(userInfo, 'email_id', '')
                  }
                </Col>
              </StyledRow>
              <StyledRow className='my-4'>
                <Col className='my-1' md={8} sm={8} xs={10} lg={8} xl={6}>
                  <b>Joining date:</b>
                </Col>
                <Col className='my-1' md={16} sm={14} xs={14} lg={16} xl={18}>
                  {joiningDate.date_th} {joiningDate.monthName} {joiningDate.year}
                </Col>
              </StyledRow>
              <StyledRow className='my-4'>
                <Col className='my-1' md={8} sm={8} xs={10} lg={8} xl={6}>
                  <b>Designation:</b>
                </Col>
                <Col className='my-1' md={16} sm={14} xs={14} lg={16} xl={18}>
                  {
                    get(userInfo, 'job_title', '')
                  }
                </Col>
              </StyledRow>
              <StyledRow>
                <Col md={8} sm={8} xs={10} lg={8} xl={6}>
                  <b>Department:</b>
                </Col>
                <Col md={16} sm={14} xs={14} lg={16} xl={18}>
                  {
                    get(userInfo, 'department', '')
                  }
                </Col>
              </StyledRow>
              <StyledRow className='my-4'>
                <Col className='my-1' md={8} sm={8} xs={10} lg={8} xl={6}>
                  <b>Payroll Number:</b>
                </Col>
                <Col className='my-1' md={16} sm={14} xs={14} lg={16} xl={18}>
                  {
                    get(userInfo, 'payroll', '')
                  }
                </Col>
              </StyledRow>
              <StyledRow className='my-4'>
                <Col className='my-1' md={8} sm={8} xs={10} lg={8} xl={6}>
                  <b>ID Number:</b>
                </Col>
                <Col className='my-1' md={16} sm={14} xs={14} lg={16} xl={18}>
                  {
                    get(userInfo, 'employer_id', '')
                  }
                </Col>
              </StyledRow>
              <StyledRow className='mt-3'>
                <Col className='my-1' md={8} sm={8} xs={10} lg={8} xl={6}>
                  <b><ResetPasswordLink onClick={() => logoutUser()}>Logout</ResetPasswordLink></b>
                </Col>
                <Col className='my-1' md={16} sm={14} xs={14} lg={16} xl={18}>
                  <ResetPasswordLink onClick={() => setOpenResetPasswordModal(true)}>Change Password</ResetPasswordLink>
                </Col>
              </StyledRow>
            </Card>
          </Col>
          { userRoleId == 2 &&
          <>
          <Col md={24} sm={24} xs={24} lg={8}>
            <Card bordered={false} style={{width:'100%', height:' 100%', padding:'20px'}}>
            <Spin spinning={displayReportLoader} >
              <div style={{width:'100%', height:' 100%',display:'flex', flexDirection:'column',justifyContent:'space-around',gap:'10px'}}>
                <Col className=' mt-1 mb-2' lg={24} style={{ fontSize : "18px", paddingLeft : "0px !important" }}><b>Report Preferences</b></Col>
                <br/>
                <StyleRow>
                  <Col className='mb-1' xs={14} lg={12}>Attendance Report</Col>
                  <Col className="align-right" xs={10} lg={12}>
                    <Switch size="small" checked = {attendanceReport} onClick={() => {handleChange(5,attendanceReport === true ? 'false' : 'true');setAttendanceReport(attendanceReport === true ? false : true)}}/>
                  </Col>
                </StyleRow>     
                <br/>
                <StyleRow>
                  <Col className='mb-1' xs={14} lg={12}>Overtime Report</Col>
                  <Col className="align-right" xs={10} lg={12}>
                    <Switch size="small" checked = {overtimeReport} onClick={() => {handleChange(6, overtimeReport === true ? 'false' : 'true');setOvertimeReport(overtimeReport === true ? false : true)}}/>
                  </Col>
                </StyleRow>  
                <br/>
                <StyleRow>
                  <Col className='' xs={14} lg={12} style={{fontWeight:'bold'}}>Exception Report :</Col>
                </StyleRow>  
                <hr/>
               <StyleRow style={{paddingLeft:'20px'}}>
                  <Col className='mb-1' xs={14} lg={12}>Late Check-in Report</Col>
                  <Col className="align-right" xs={10} lg={12}>
                  <Switch size="small" checked = {lateCheckinReport} onClick={() => {handleChange(7, lateCheckinReport === true ? 'false' : 'true');setLateCheckinReport(lateCheckinReport === true ? false : true)}} />
                  </Col>
                </StyleRow>  
                <br/>
                <StyleRow style={{paddingLeft:'20px'}}>
                  <Col className='mb-1' xs={16} lg={14}>Early Check-out Report</Col>
                  <Col className="align-right" xs={8} lg={10}>
                    <Switch size="small" checked = {earlyCheckoutReport} onClick={() => {handleChange(8, earlyCheckoutReport === true ? 'false' : 'true');setEarlyCheckoutReport(earlyCheckoutReport === true ? false : true)}} />
                  </Col>
                </StyleRow>  
                <br/>
                <StyleRow style={{paddingLeft:'20px'}}>
                  <Col className='mb-1' xs={14} lg={12}>Absent Report</Col>
                  <Col className="align-right" xs={10} lg={12}>
                    <Switch size="small" checked = {absentReport} onClick={() =>{handleChange(9, absentReport === true ? 'false' : 'true');setAbsentReport(absentReport === true ? false : true)}} />
                  </Col>
                </StyleRow> 
              </div>
              </Spin>
            </Card>        
          </Col>
          </>
          }
        </Row>
      </StyledProfileContainer>
      <ResetPasswordModal
        openResetPasswordModal={openResetPasswordModal}
        toggleResetPasswordModal={() => setOpenResetPasswordModal(!openResetPasswordModal)}
      ></ResetPasswordModal>
    </>
  );
}

const mapStateToProps = state => ({
  userInfo: getUserInfo(state),
  notificationList: getNotificationList(state),
  userRoleId: getUserRoleId(state),
});
const mapDispatchToProps = dispatch => bindActionCreators(
  {
    removeStoreData: clearStore
  },
  dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);

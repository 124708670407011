import React, { useRef, useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Spin,
  Select,
  DatePicker,
  Space,
  Form,
  message,
} from "antd";
import Button from "atoms/Button";
import moment from "moment";
import styled from "@emotion/styled";
import { CSVLink } from "react-csv";
// Redux
import { connect } from "react-redux";
import {
  getUserRoleId,
  getAllDepartmentList,
  getSelectedDepartmentId,
  getShiftTimeMaster,
} from "redux/selectors";
import { CALL_API } from "common/API";
import {
  MONTH_LIST,
  REPORT_DURATION_TYPES,
  STATUS_CODE,
} from "common/Constants";

import {
  addDaysinDate,
  dateInDetail,
  getKenyanDateTime,
  getMonthDateRange,
  getMySqlDate,
  momentDate,
  addMonthinDate,
} from "utils/Date";
import COLORS from "common/Colors";
import { concat, find, get, includes, orderBy, reduce } from "lodash";
import ViewMore from "organisms/ViewMore";

const StyledTable = styled(Table)`
  height: 600px !important; 
  padding-top: 20px;
  .ant-table-content {
    overflow-x: auto;
    max-height: 500px;
    white-space: nowrap;
  }
  table {
    width: 100% !important;
  }
  .ant-table-cell {
    padding: 20px;
  }
  .ant-table-cell:last-child {
    min-width: 200px;
    white-space: normal;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
  }
  .non-white {
    background: #f9f9f9;
  }
`;

const StyledApprovalSearchForm = styled(Form)`
  .ant-form-item-with-help {
    margin-bottom: 0px;
    .ant-form-item-explain-connected {
      display: none;
    }
  }
  .ant-form-item {
    display: inline-block;
  }
  .outer-form {
    margin-right: 0px;
  }
  .ant-select-selector {
    max-width: 180px;
    min-width: 180px;
    margin-bottom: 10px;
    width: 180px;
  }
`;
const StyledButton = styled(Button)`
  width: 120px;
  border: 1px solid ${COLORS.PRIMARY};
  color: ${COLORS.PRIMARY};
  width: initial;
  background: transparent;
`;

function EarlyCheckOut({
  userRoleId,
  allDepartmentList,
  selectedDepartmentId,
}) {
  const [displayTableLoader, setDisplayTableLoader] = useState(false);
  const [earlyCheckOutList, setearlyCheckOutList] = useState([]);
  const [CSVearlyCheckOutList, setCSVearlyCheckOutList] = useState([]);
  const [form] = Form.useForm();
  const csvLinkEl = useRef();
  const filterList = [];
  const [monthList, setMonthList] = useState([]);

  const curr_month = moment().month() + 1;

  const list = () => {
    let month_list = [];
    for (let i = 0; i < curr_month; i++) {
      month_list.push(MONTH_LIST[i]);
    }
    setMonthList(month_list);
  };

  const STAFF_LIST_ALL = earlyCheckOutList.map((val) => {
    return { user_id: val.EmployeeId, name: val.name };
  });

  for (let i = 0; i < STAFF_LIST_ALL.length; i++) {
    filterList.push(STAFF_LIST_ALL[i]);
  }

  const STAFF_LIST = [...new Set(filterList.map((a) => JSON.stringify(a)))].map(
    (a) => JSON.parse(a)
  );
  const filterearlycheckoutlist = earlyCheckOutList.filter((val) => {
    if (form.getFieldValue("staff_name") === "") {
      return val;
    } else if (val?.EmployeeId === form.getFieldValue("staff_name")) {
      return val;
    }
  });

  const CSVHeaders = [
    { label: "Employee ID" },
    { label: "Name" },
    { label: "Department" },
    { label: "Date" },
    { label: "Check-in Time" },
    { label: "Check-out Time" },
    { label: "Check-out cutoff" },
    { label: "end Time" },
    { label: "Time left for actual departure (in hrs)" },
    { label: "Reason" },
  ];

  const columns = [
    {
      title: "Employee ID",
      dataIndex: "EmployeeId",
      key: "EmployeeId",
      width: "5%",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "5%",
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      width: "5%",
    },
    {
      title: "Check-in Date",
      dataIndex: "date",
      key: "date",
      width: "5%",
      render: (date) => {
        const dateFormat = moment(date).format("DD MMM YYYY");
        return `${dateFormat}`;
      },
    },
    {
      title: "Check-in Day",
      dataIndex: "date",
      key: "date",
      width: "5%",
      render: (date) => {
        const getdayAlone = moment(date).format("dddd");
        return `${getdayAlone}`;
      },
    },
    {
      title: "Check-in time",
      dataIndex: "start_time",
      key: "start_time",
      width: "5%",
    },
    {
      title: "Check-out Date",
      dataIndex: "checkout_date",
      key: "checkout_date",
      width: "7%",
      render: (date) => {
        const dateFormat = moment(date).format("DD MMM YYYY");
        return `${dateFormat}`;
      },
    },
    {
      title: "Check-out time",
      dataIndex: "end_time",
      key: "end_datetime",
      width: "5%",
    },
    {
      title: "Check-out cutoff",
      dataIndex: "shiftEndTimeCutOff",
      key: "shiftEndTimeCutOff",
      width: "5%",
    },
    {
      title: "Time left for actual departure (in hrs)",
      dataIndex: "earlyCheckOut",
      key: "earlyCheckOut",
      width: "5%",
    },

    {
      title: "Reason",
      dataIndex: "early_checkout_reason",
      key: "early_checkout_reason",
      width: "5%",
      render: (reason) => {
        return (
          <>
            <ViewMore value={reason} />
          </>
        );
      },
    },
  ];

  useEffect(() => {
    list();
    getAttendanceReport();
  }, [
    selectedDepartmentId,
    userRoleId,
    form.getFieldValue("duration_type"),
    form.getFieldValue("end_date"),
    form.getFieldValue("month"),
    form.getFieldValue("start_date"),
    form.getFieldValue("department"),
  ]);

  // Function to generate report
  const getAttendanceReport = async () => {
    return new Promise(async (resolve, reject) => {
      setearlyCheckOutList([]);
      const { start_date, end_date, duration_type, month, department } =
        form.getFieldsValue();
      setDisplayTableLoader(true);
      const { startDateOfMonth, lastDateOfMonth } = getMonthDateRange(
        dateInDetail(getKenyanDateTime()).year,
        get(find(MONTH_LIST, { value: month }), "index", 0)
      );
      const { code, earlyCheckOutList = [] } = await CALL_API(
        "earlycheckout-report",
        "post",
        {
          department:
            userRoleId === 1 || userRoleId === 6
              ? [department]
              : selectedDepartmentId,
          start_date: getMySqlDate(
            duration_type === 2 ? startDateOfMonth : start_date
          ),
          end_date: getMySqlDate(
            duration_type === 2 ? lastDateOfMonth : end_date
          ),
        }
      );
      setDisplayTableLoader(false);
      if (includes([STATUS_CODE.SUCCESS, STATUS_CODE.RECORD_NOT_FOUND], code)) {
        let recordList = [];
        earlyCheckOutList.map((list) => {
          // note: First parameter is needed
          Object.entries(list).forEach(([key, value]) => {
            recordList = concat(recordList, value);
          });
        });
        setearlyCheckOutList(orderBy(recordList, ["date"], ["desc"]));
      }
    });
  };

  // Function to validate date Range
  const validateDateRange = (date) => {
    const endDate = moment(form.getFieldValue("end_date"));
    if (endDate < date) {
      form.setFieldsValue({ end_date: date });
    }
  };

  // Function to Prepare CSV
  const prepareCSV = async () => {
    if (filterearlycheckoutlist?.length > 0) {
      setCSVearlyCheckOutList(
        reduce(
          filterearlycheckoutlist,
          function (result, record) {
            const {
              date_th: startDate_th,
              shortMonthName: startMonthName,
              year: end_year,
            } = dateInDetail(record?.date);
            const {
              date: startDate_th1,
              shortMonthName: startMonthName1,
              year: end_year1,
            } = dateInDetail(record?.checkout_date);
            result.push({
              "Employee ID": record?.EmployeeId,
              "Name": record?.name,
              "Department": record?.department,
              "Check-in Date": `${startDate_th} ${startMonthName} ${end_year}`,
              "Check-in Day": `${moment(record?.date).format("dddd")}`,
              "Check-in Time": record?.start_time,
              "Check-out Date": `${startDate_th1} ${startMonthName1} ${end_year1}`,
              "Check-out Time": record?.end_time,
              "Check-out cutoff": record?.shiftEndTimeCutOff,
              "Time left for actual departure (in hrs)": record?.earlyCheckOut,
              "Reason":
                record?.early_checkout_reason === "" || null
                  ? "-"
                  : record?.early_checkout_reason,
            });
            return result;
          },
          []
        )
      );

      setTimeout(() => {
        csvLinkEl.current.link.click();
      });
    } else {
      message.error(`No data available to download.`);
    }
  };

  const paginationOptions = {
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    pageSize: 20,
    showSizeChanger: false,
  };

  return (
    <>
      <Row className="page-title">
        <Col xs={24} sm={19} md={19} className="page-title-head">
          <Space>
            <StyledApprovalSearchForm
              form={form}
              name="approval_form"
              layout="inline"
              initialValues={{
                staff_name: "",
                duration_type: 2,
                month: dateInDetail(getKenyanDateTime()).monthName,
                start_date: momentDate(addMonthinDate(getKenyanDateTime(), -1)),
                end_date: momentDate(addDaysinDate(getKenyanDateTime())),
                department: "",
              }}
              onFinish={getAttendanceReport}
            >
              <Form.Item
                name="duration_type"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  placeholder="Select..."
                  options={REPORT_DURATION_TYPES}
                />
              </Form.Item>

              <Form.Item className="outer-form" shouldUpdate>
                {({ getFieldValue }) => {
                  const duration_type = getFieldValue("duration_type");
                  return duration_type === 1 ? (
                    <>
                      <Form.Item
                        name="start_date"
                        rules={[
                          () => ({
                            // note: First parameter is needed
                            validator(rule, value) {
                              const date = moment(value);
                              return date.isValid()
                                ? Promise.resolve()
                                : Promise.reject();
                            },
                          }),
                        ]}
                      >
                        <DatePicker
                          placeholder="Start date"
                          onChange={validateDateRange}
                          allowClear={false}
                        />
                      </Form.Item>

                      <Form.Item
                        name="end_date"
                        rules={[
                          () => ({
                            // note: First parameter is needed
                            validator(rule, value) {
                              const date = moment(value);
                              return date.isValid()
                                ? Promise.resolve()
                                : Promise.reject();
                            },
                          }),
                        ]}
                      >
                        <DatePicker
                          placeholder="End date"
                          onChange={validateDateRange}
                          allowClear={false}
                        />
                      </Form.Item>
                    </>
                  ) : (
                    <Form.Item
                      name="month"
                      rules={[
                        {
                          required: true,
                          message: "",
                        },
                      ]}
                    >
                      <Select placeholder="Select..." options={monthList} />
                    </Form.Item>
                  );
                }}
              </Form.Item>
              <Form.Item
                name="staff_name"
                rules={[
                  {
                    required: false,
                    message: "",
                  },
                ]}
              >
                <Select
                  placeholder="Select Employee"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.name ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.name ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.name ?? "").toLowerCase())
                  }
                  options={concat(
                    {
                      user_id: "",
                      name: "All Employee",
                    },
                    STAFF_LIST
                  )}
                  fieldNames={{
                    label: "name",
                    value: "user_id",
                  }}
                />
              </Form.Item>
              {(userRoleId === 1 || userRoleId === 6) && (
                <Form.Item name="department">
                  <Select
                    placeholder="Select Department"
                    options={concat(
                      {
                        department_id: "",
                        department_name: "All Departments",
                      },
                      allDepartmentList
                    )}
                    fieldNames={{
                      label: "department_name",
                      value: "department_id",
                    }}
                  />
                </Form.Item>
              )}
            </StyledApprovalSearchForm>
          </Space>
        </Col>
        <Col xs={24} sm={4} md={4} className="align-right">
          <StyledButton
            onClick={prepareCSV}
            disabled={earlyCheckOutList?.length === (0 || null || undefined)}
          >
            Export CSV file
          </StyledButton>
          <CSVLink
            header={CSVHeaders}
            data={CSVearlyCheckOutList}
            filename={"earlycheckout-report.csv"}
            asyncOnClick={true}
            ref={csvLinkEl}
          />
        </Col>
      </Row>
      <Spin spinning={displayTableLoader}>
        <StyledTable
          dataSource={filterearlycheckoutlist}
          columns={columns}
          // note: First parameter is needed
          rowClassName={(record, index) => (index % 2 === 0 ? "" : "non-white")}
          rowKey="checkin_id"
          id="Table"
          pagination={
            filterearlycheckoutlist?.length > 20 ? paginationOptions : false
          }
        />
      </Spin>
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  allDepartmentList: getAllDepartmentList(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  shiftTimeMaster: getShiftTimeMaster(state),
});

export default connect(mapStateToProps, null)(EarlyCheckOut);

import React, { useEffect, useState } from "react";
import {
  Layout,
  Row,
  Col,
  Avatar,
  Modal,
  Skeleton,
  Tooltip,
  Popover,
  Badge,
  Dropdown,
  Menu,
} from "antd";
import { Link } from "react-router-dom";
import logo from "../assets/tymeplus.png";
import Icon, { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import OutlinedInput from "@mui/material/OutlinedInput";
import InfoIcon from "@mui/icons-material/Info";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import { Select as MUISelect, Chip, Box, createTheme } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useMedia } from "react-use";
// Lodash
import { startCase, get, find, result } from "lodash";
// Redux
import { connect } from "react-redux";
import {
  getUserName,
  getSelectedDepartmentId,
  getDepartmentList,
  getRoleName,
  getDepartmentName,
  getLoadingDataFirstTime,
  getUserRoleId,
  getRoleList,
  getNotificationList,
  getUserInfo,
  getProfilePic,
  getAllDepartmentList,
} from "redux/selectors";
import { bindActionCreators } from "redux";
import {
  setDepartmentId,
  setNotificationList,
  clearStore,
} from "redux/actions";
import { CALL_API } from "common/API";
// Theme
import styled from "@emotion/styled";
import COLORS from "common/Colors";
import Button from "atoms/Button";
// Components
import CustomIcon from "atoms/Icon";
import SwitchUserModal from "organisms/SwitchUserModal";
import Notifications from "organisms/Notifications";
import { DEVICE } from "common/Device";

const AntHeader = Layout.Header;
const StyledCol = styled(Col)`
  text-align: right;
  @media ${DEVICE.mobileL} {
    margin-left: 0px;
  }
  @media ${DEVICE.mobileM} {
    margin-left: 0px;
    text-align: none;
  }
`;
const StyledImgRow = styled(Row)`
  img{
    height: 45px;
    margin-top: 5px;
  }
`;

const StyledIconContainer = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  display: inline-block;
  white-space: pre;
  @media ${DEVICE.tablet} {
    margin-top: -50px;
    text-align: right;
  }
  @media ${DEVICE.mobileL} {
    .select {
      margin-top: 65px;
      text-align: right;
    }
  }
`;

const StyledAntHeader = styled(AntHeader)`
  padding: 0px;
  box-shadow: 0px 2px 5px 0px #b1b1b1;
  background: #ffffff;
  z-index: 9;
  height: auto;
  .left-header-skeleton {
    margin: 10px;
  }
  .right-header-skeleton {
    margin: 10px;
  }
`;

const StyledCustomIcon = styled(Icon)`
  padding: 5px 10px;
  svg {
    height: 16px !important;
  }
  button{
    background: transparent;
    border: none;
  }
`;

const StyledSwitchButton = styled(Button)`
  background-color: ${COLORS.PRIMARY};
  margin-right: 0.7rem;

  &:hover {
    background-color: ${COLORS.PRIMARY};
  }
  @media (min-width: 320px) and (max-width: 374px) {
    margin-top: 65px;
    margin-left: -72px;
  }
  @media (min-width: 375px) and (max-width: 424px) {
    margin-top: 65px;
    margin-left: -90px;
  }
  @media (min-width: 425px) and (max-width: 550px) {
    margin-top: 65px;
    margin-left: -86px;
  }
`;

const StyledAvatar = styled(Avatar)`
  vertical-align:'middle';
  background-color: ${COLORS.PRIMARY};
  margin-right: 0.5rem;
  margin-bottom: 5px;
`;

const StyleSuperHODDiv = styled.div`
  padding-left: 5px;
  padding-top: 10px;
  .hide {
    display: inline;
  }
  .dept_info {
    min-width: 300px;
    max-width: 300px;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre;
    }
  }
  span {
    line-height: 20px;
    display: block;
  }
  img {
    display: none;
  }
  @media ${DEVICE.tablet} {
    .hide {
      display: none;
    }
    span {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    img {
      display: inline;
    }
  }
  @media (max-width: 1250px) {
    span {
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  @media (max-width: 320px) {
    span {
      max-width: 160px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

const menuIconStyle = `
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
`;

const StyledMenuUnfoldOutlined = styled(MenuUnfoldOutlined)`
  ${menuIconStyle}
`;
const StyledMenuFoldOutlined = styled(MenuFoldOutlined)`
  ${menuIconStyle}
`;

const StyledMenuToggle = styled.div`
  display: none;
  @media ${DEVICE.tablet} {
    display: inline;
    float: left;
    margin-left: 3px;
    padding: 10px;
    .anticon-menu-fold,
    .anticon-menu-unfold {
      padding: 2px 0px 0px 0px;
    }
  }

  ${({ collapsed }) => collapsed && `margin-left: -10px`}
`;
const StyledMenuToggle1 = styled.div`
  display: inline;
  float: left;
  padding: 10px;
  .anticon-menu-fold,
  .anticon-menu-unfold {
    padding: 2px 0px 0px 0px;
  }

  ${({ collapsed }) => collapsed && `margin-left: -10px`}
  @media ${DEVICE.tablet} {
    display: none;
  }
`;

const StyledRoleSpan = styled.span`
  ${({ userroleid }) =>
    (userroleid === 1 || userroleid === 6) && `padding-top: 10px`}
`;

const StyledHeaderIcons = styled.div`
  display: inline-block;
  @media ${DEVICE.tablet} {
    margin-top: -50px;
    position: absolute;
    top: -54px;
    right: 0px;
  }
`;

const StyledNotificationHeaderRow = styled(Row)`
  .title {
    font-size: 1.1rem;
  }
  .clear {
    text-align: right;
    font-size: 0.7rem;
    padding-top: 10px;
    color: #5b5bff;
    cursor: pointer;
  }
`;

const StyledDeptNameSpan = styled.span`
  min-width:200px;
`;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 2;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      fontFamily: "Segoe UI Symbol",
      fontSize: "14px",
      overflow: "auto",
    },
  },
};

const customizeMenu = createTheme({
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: "0px",
          whiteSpace: "unset",
        },
      },
    },
  },
});
const ontlineInput = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          fontFamily: "Segoe UI Symbol",
          borderWidth: "1px",
          maxHeight: "35px",
          "&:focus": {
            borderWidth: "1px",
          },
        },
      },
    },
  },
});
const theme = createTheme({
  components: {
    MuiListItemText: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          fontFamily: "Segoe UI Symbol",
          color: "#0000000",
          fontWeight: 400,
          padding: "5px",
        },
      },
    },
  },
});

const Header = ({
  setLoggedIn,
  setPageTitle,
  departmentList,
  selectedDepartmentId,
  roleName,
  departmentName,
  updateDepartmentId,
  userName,
  collapsed,
  toggleCollapse,
  isLoadingDataFirstTime,
  removeStoreData,
  userRoleId,
  roleList,
  saveNotificationList,
  notificationList,
  showDrawer,
  userInfo,
  profilePic,
  allDepartmentList,
}) => {
  const [open, setOpen] = useState(false);
  const [userRoleModal, setUserRoleModal] = useState(false);
  const [deptFullList, setDeptFullList] = useState([]);

  let history = useHistory();
  const logoutUser = () => {
    Modal.confirm({
      icon: "",
      content: "Are you sure you want to logout?",
      okText: "Logout",
      cancelText: "Cancel",
      onOk: () => {
        setTimeout(() => {
          window.localStorage.removeItem("Tymeplusjwt");
          window.localStorage.removeItem("login_reminder");
          setLoggedIn();
          removeStoreData();
          history.push(`/`);
        }, 500);
      },
    });
  };

  useEffect(() => {
    intialFunction();
  }, [departmentName]);

  const intialFunction = async () => {
    const newArr = [];
    await departmentList.forEach((element) => {
      selectedDepartmentId.forEach((e) => {
        if (element.department_id == e) {
          newArr.push(element.department_name);
        }
      });
    });
    setDeptFullList(newArr);
  };

  const handleCheckBoxChange = (event) => {
    let a = event.target.value;
    const newArr = [];
    departmentList.forEach((element) => {
      a.forEach((e) => {
        if (element.department_name == e) {
          newArr.push(element.department_id);
        }
      });
      updateDepartmentId(newArr);
    });
    setDeptFullList(typeof a === "string" ? a.split(",") : a);
  };

  const clearNotifications = () => {
    Modal.confirm({
      icon: "",
      content: "Are you sure you want to clear notifications",
      okText: "YES",
      cancelText: "No",
      onOk: async () => {
        await CALL_API("clear-notification", "post", {});
        saveNotificationList([]);
      },
    });
  };

  const notificationreadcound = async () => {
    await CALL_API("notification-read", "patch", {});
  };

  const getNotificationList = async () => {
    const { list } = await CALL_API("notification-list", "post", {});
    saveNotificationList(list);
  };

  useEffect(() => {
    getNotificationList();
    const timer = setInterval(() => {
      getNotificationList();
    }, 3000);
    return () => clearInterval(timer);
  }, []);

  const headerSkeleton = (classname) => (
    <Skeleton.Input className={classname} active={true} size="small" />
  );

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
    notificationreadcound();
  };

  const notificationunreadcound = notificationList.filter((val) => {
    if (JSON.stringify(val?.status_id)?.toLowerCase()?.includes("1")) {
      return val;
    }
  });
  const ExtramediumDevice = useMedia("(min-width: 1200px)");
  const mediumDevice = useMedia("(min-width: 900px)");

  function handleLogin() {
    const jwtToken = window.localStorage.getItem("Tymeplusjwt");
    // Build the hyperlink to management View with the token as a query parameter
    const hyperlink = `https://gcs-manage.tymeplus.online?jwtToken=${jwtToken}`;
    // Open the hyperlink in a new window
    window.open(hyperlink, "_blank");
  }

  return (
    <StyledAntHeader>
      <Row>
        <Col xs={24} sm={24} md={6} lg={12}>
          {isLoadingDataFirstTime && headerSkeleton("left-header-skeleton")}
          {!isLoadingDataFirstTime && (
            <StyleSuperHODDiv>
              <Row>
                <Col>
                  <StyledImgRow>
                    <img
                      src={logo}
                      alt="Tymeplus"
                    />
                  </StyledImgRow>

                  <StyledMenuToggle1 className="hide" collapsed={collapsed}>
                    {React.createElement(
                      collapsed
                        ? StyledMenuFoldOutlined
                        : StyledMenuUnfoldOutlined,
                      {
                        onClick: toggleCollapse,
                      }
                    )}
                  </StyledMenuToggle1>

                  <StyledMenuToggle collapsed={collapsed}>
                    {React.createElement(
                      collapsed
                        ? StyledMenuFoldOutlined
                        : StyledMenuUnfoldOutlined,
                      {
                        onClick: showDrawer,
                      }
                    )}
                  </StyledMenuToggle>
                  <div className="dept_info">
                    {userRoleId !== 5 && (
                      <StyledRoleSpan userroleid={userRoleId}>
                        Role: {roleName}
                      </StyledRoleSpan>
                    )}
                    {userRoleId !== 6 && (
                      <>
                        {
                          userRoleId > 1 &&
                            userRoleId !== 5 &&
                            userRoleId !== 4 &&
                            departmentName && (
                              <span>
                                Department: {departmentName.join(", ")}
                              </span>
                            )
                        }
                        {userRoleId === 4 && (
                          <StyledDeptNameSpan>
                            Department:{" "}
                            {result(
                              find(allDepartmentList, (e) => {
                                return (
                                  e.department_id === get(userInfo, "staff_department_id", "")
                                );
                              }),
                              "department_name"
                            )}
                          </StyledDeptNameSpan>
                        )}
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </StyleSuperHODDiv>
          )}
        </Col>
        <StyledCol xs={24} sm={24} md={18} lg={12}>
          <StyledIconContainer>
            {isLoadingDataFirstTime && (
              <>
                {headerSkeleton("right-header-skeleton")}
                {headerSkeleton("right-header-skeleton")}
              </>
            )}
            {!isLoadingDataFirstTime && (
              <>
                {userRoleId === 6 && (
                  <StyledSwitchButton type="primary" onClick={handleLogin}>
                    Management View
                  </StyledSwitchButton>
                )}
                {roleList.length > 1 && (
                  <StyledSwitchButton
                    type="primary"
                    onClick={() => setUserRoleModal(true)}
                  >
                    Switch user
                  </StyledSwitchButton>
                )}
                {departmentList.length > 1 && userRoleId !== 4 && (
                  <FormControl
                    className="select"
                    sx={{
                      m: 1,
                      width: ExtramediumDevice  ? "270px" : mediumDevice  ? "120px" : "140px",
                      marginTop: 2,
                      fontFamily: "Segoe UI Symbol",
                    }}
                  >
                    <MUISelect
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={deptFullList}
                      onChange={handleCheckBoxChange}
                      input={
                        <OutlinedInput
                          theme={ontlineInput}
                          sx={{ height: 50 }}
                        />
                      }
                      defaultValue={selectedDepartmentId}
                      renderValue={(selected) => (
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 0.2,
                            maxHeight: 30,
                            overflow: "hidden",
                          }}
                        >
                          {selected.map((value) => (
                            <Chip
                              key={value}
                              label={value}
                              sx={{
                                borderRadius: "4px",
                                width: "75px",
                                height: "29px",
                              }}
                              className="text-truncate"
                            />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {departmentList?.map((name) => (
                        <MenuItem
                          disableGutters={true}
                          key={name?.department_name}
                          value={name?.department_name}
                          theme={customizeMenu}
                          selected={false}
                          disabled={deptFullList.length === 1 && deptFullList[0] === name.department_name}
                        >
                          <Checkbox
                            checked={
                              deptFullList?.indexOf(name?.department_name) > -1
                            }
                          />
                          <ListItemText
                            disableTypography={true}
                            theme={theme}
                            primary={name?.department_name}
                          />
                        </MenuItem>
                      ))}
                    </MUISelect>
                  </FormControl>
                )}
                <StyledHeaderIcons>
                  {userRoleId !== 5 && (
                    <Link
                      to={`my-profile`}
                      onClick={() => setPageTitle("Notification")}
                    >
                      <Tooltip placement="bottom" title={userName}>
                        {profilePic === "" || profilePic === null || profilePic === undefined || profilePic == "null"  ? (
                          <>
                            <StyledAvatar
                              size="default"
                            >
                              {startCase(get(userInfo, "name", "").charAt(0))}
                            </StyledAvatar>
                          </>
                        ) : (
                          <>
                            <StyledAvatar
                              src={profilePic}
                              size="default"
                            ></StyledAvatar>
                          </>
                        )}
                      </Tooltip>
                    </Link>
                  )}
                  <Dropdown
                    placement="bottomRight"
                    arrow={{ pointAtCenter: true }}
                    trigger={["click", "hover"]}
                    overlay={
                      <Menu>
                        <Menu.Item>
                          <Link to={`released-notes`}>Release Notes</Link>
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <InfoIcon />
                  </Dropdown>
                      {userRoleId !== 5 && (
                        <Tooltip placement="bottom" title="Notifications">
                          {navigator.userAgent !== "application" && (
                            <Popover
                              placement="bottomRight"
                              title={
                                <StyledNotificationHeaderRow>
                                  <Col className="title" span={12}>
                                    Notifications
                                  </Col>
                                  {notificationList.length > 0 && (
                                    <Col
                                      className="clear"
                                      span={12}
                                      onClick={clearNotifications}
                                    >
                                      Clear Notifications
                                    </Col>
                                  )}
                                </StyledNotificationHeaderRow>
                              }
                              open={open}
                              content={<Notifications setOpen={setOpen} />}
                              trigger="click"
                              overlayClassName="notification-popover"
                              onOpenChange={handleOpenChange}
                            >
                              <StyledCustomIcon
                                component={() => (
                                  <button>
                                    <Badge
                                      count={notificationunreadcound.length}
                                      size="small"
                                    >
                                      <CustomIcon name="notification" />
                                    </Badge>
                                  </button>
                                )}
                              />
                            </Popover>
                          )}
                          {navigator.userAgent === "application" && (
                            <Link
                              to={`notification`}
                              onClick={() => setPageTitle("Notification")}
                            >
                              <StyledCustomIcon
                                component={() => (
                                  <Badge
                                    count={notificationList.length}
                                    size="small"
                                  >
                                    <CustomIcon name="notification" />
                                  </Badge>
                                )}
                              />
                            </Link>
                          )}
                        </Tooltip>
                    
                  )}
                  <Tooltip placement="bottom" title="Logout">
                    <StyledCustomIcon
                      component={() => (
                        <CustomIcon name="logout" className="icon" />
                      )}
                      onClick={logoutUser}
                    />
                  </Tooltip>
                </StyledHeaderIcons>
              </>
            )}
          </StyledIconContainer>
        </StyledCol>
      </Row>

      <SwitchUserModal
        onDashboardPg={true}
        userRoleModal={userRoleModal}
        closeModal={() => setUserRoleModal(false)}
      />
    </StyledAntHeader>
  );
};

const mapStateToProps = (state) => ({
  userName: getUserName(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  departmentList: getDepartmentList(state),
  roleName: getRoleName(state),
  departmentName: getDepartmentName(state),
  isLoadingDataFirstTime: getLoadingDataFirstTime(state),
  userRoleId: getUserRoleId(state),
  roleList: getRoleList(state),
  notificationList: getNotificationList(state),
  userInfo: getUserInfo(state),
  profilePic: getProfilePic(state),
  allDepartmentList: getAllDepartmentList(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateDepartmentId: setDepartmentId,
      saveNotificationList: setNotificationList,
      removeStoreData: clearStore,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Header);

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import './index.css';
import './index.less';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import store from './redux/store';
import { persistor, store } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';


// console.log(window.location.search.indexOf('app') >= 0)
// const history = createBrowserHistory();
// let path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
const activePage = window.location.pathname.replace('/', '');
// if (path) {
  // const pathArr = path.split('/');
  // const product = pathArr.length > 1 ? pathArr[1] : '';
  // rmt_parent_product = product;
  // window.localStorage.setItem('rmt_parent_product', product);

  // const regex = new RegExp(`${product}(.*)$`);
  // path = (regex.exec(window.location.hash) || [])[1];
  // if (path) history.replace(path);
// }
// if (path) history.replace(path);
// console.log(path)

let base64Val = '';
let nameVal = '';
let MIMEtypeVal = '';
function uploadDocumentApp(base64, name, MIMEtype) {
  console.log(base64);
  console.log(name);
  console.log(MIMEtype);
  base64Val = base64;
  nameVal = name;
  MIMEtypeVal = MIMEtype;
}

ReactDOM.render(
  <Provider store={store}>
    {/* <React.StrictMode> */}
    <PersistGate loading={null} persistor={persistor}>
      <App activePage={activePage} isApp={window.location.search.indexOf('app') >= 0} documentData={{base64Val, nameVal, MIMEtypeVal}}/>
    </PersistGate>
    {/* </React.StrictMode> */}
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { useEffect, useState } from 'react';
import { Bar } from '@ant-design/plots';
import styled from '@emotion/styled';
import { Spin } from 'antd';
// Redux
import { connect } from 'react-redux';
import {
  getUserRoleId,
  getSelectedDepartmentId
} from 'redux/selectors';
import DepartmentAttendanceReport from './DepartmentAttendanceReport';
import AttendanceStaffList from './AttendanceStaffList';
import { CALL_API } from 'common/API';
import { concat, includes, isNaN, map, max, size } from 'lodash';


const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;

const StyledChartContainer = styled.div`
  border: solid 1px #9B9B9B;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 15px;
  padding-bottom:35px;
  padding-top:25px;
  .name-title {
    padding: 3px 10px;
    font-size: 1rem;
    padding-bottom: 15px;
  }
`;

function AttendanceBarChart({
  userRoleId,
  selectedDepartmentId
}) {
  const [displayDepartmentAttendanceReportModal, toggleDepartmentAttendanceReportModal] = useState(false);
  const [displayAttendanceStaffModal, toggleDisplayAttendanceStaffModal] = useState(false);
  const [reportType, setReportType] = useState('');
  const [reportName, setReportName] = useState('');
  const [reportList, setReportList] = useState([])
  const [reportColor, setReportColor] = useState('');
  const [attendanceReportTitle, setAttendanceReportTitle] = useState('');
  const [displayReportLoader, toggleReportLoader] = useState(false);
  const [absentList, setAbsentList] = useState([]);
  const [lateListData, setLateListData] = useState([]);
  const [onTimeList, setOnTimeList] = useState([]);
  const [leaveList, setLeaveList] = useState([]);
  const [reportData, setReportData] = useState([
    {
      name: 'onTime',
      type: 'Present',
      numbers: 0,
      list: [],
    },
    {
      name: 'Absent',
      type: 'Absent',
      numbers: 0,
      list: [],
    },
    {
      name: 'Late',
      type: 'Present',
      numbers: 0,
      list: [],
    },
    {
      name: 'Annual Leave',
      type: 'leave',
      numbers: 0,
      list: [],
    },
    {
      name: 'Sick Leave',
      type: 'leave',
      numbers: 0,
      list: [],
    },
    {
      name: 'Other Leave',
      type: 'leave',
      numbers: 0,
      list: [],
    },
  ]);
  const barStyle = { opacity: 0}
  const config = {
    isStack: true,
    dodgePadding: .2,
    data: reportData,
    height: 314,
    xField: 'numbers',
    yField: 'type',
    seriesField: 'name',
    intervalPadding: 10,
    legend: false,
    color: ({ name }) => {
      if (name === 'Absent') {
        return '#FDA90D';
      } else if (name === 'onTime') {
        return '#0565f5';
      } else if (name === 'Late') {
        return '#5d9af5'
      } else if (name === 'Annual Leave') {
        return '#bd34eb'
      } else if (name === 'Sick Leave') {
        return '#e397fc'
      } else if (name === 'Others') {
        return '#96d9ff'
      } else {
        return '#f5e0ff'
      }
    },
    theme: {
      maxColumnWidth: 50,
      minColumnWidth: 50,
    },
    label: {
      position: 'middle',
      content: '',
    },
    columnStyle: {
      cursor: 'pointer'
    },
    xAxis: {
      max: isNaN(max(map(reportData, 'numbers'))) ? 100 : max(map(reportData, 'numbers')) + 15,
      grid: {
        line: {
          style: barStyle
        }
      }
    }
  };

  useEffect(() => {
    if ((userRoleId !== 1 && selectedDepartmentId !== 0) || userRoleId === 1 || userRoleId === 6) {
      getReportData();
    }
  }, []);

  useEffect(() => {
    if (userRoleId !== 1 || userRoleId !== 6) {
      getReportData();
    }
  }, [selectedDepartmentId]);

  const getReportData = async () => {
    toggleReportLoader(true);
    const data = await CALL_API('attendance-service', 'post', {
      method: 'todayOverallPresentAbsentLeavePostCutOff',
      parameters: {
        departmentId: (userRoleId !== 1 && userRoleId !== 6) ? selectedDepartmentId : ['']
      },
    });
    let absentList = []
    let ontimeList = []
    let lateList = []
    let leaveList = []
    data.map(list => {
      //note: First parameter is needed
      Object.entries(list).forEach(([key, value]) => {
        absentList = concat(absentList, value.absent)
        ontimeList = concat(ontimeList, value.onTime)
        lateList = concat(lateList, value.late)
        leaveList = concat(leaveList, value.leaves)

      })
    })

    let dupeReportData = Array.from(reportData);

    dupeReportData[0].numbers = size(ontimeList)
    dupeReportData[1].numbers = size(absentList)
    dupeReportData[2].numbers = size(lateList)

    dupeReportData[0].list = ontimeList
    dupeReportData[1].list = absentList
    dupeReportData[2].list = lateList

    let sickCount = 0
    let sickLeaveList = []
    let annualCount = 0
    let annualLeaveList = []
    let otherCount = 0
    let otherLeaveList = []
    leaveList.map((list) => {
      if (list.leave_category_id == 1) {
        sickCount++
        sickLeaveList.push(list)
      }
      else if (list.leave_category_id == 7) {
        annualCount++
        annualLeaveList.push(list)
      }
      else {
        otherCount++
        otherLeaveList.push(list)
      }
    })
    dupeReportData[5].numbers = otherCount
    dupeReportData[5].list = otherLeaveList
    dupeReportData[4].numbers = sickCount
    dupeReportData[4].list = sickLeaveList
    dupeReportData[3].numbers = annualCount
    dupeReportData[3].list = annualLeaveList
    setReportData(dupeReportData);
    toggleReportLoader(false);

    setAbsentList(absentList);
    setLateListData(lateList);
    setOnTimeList(ontimeList);
    setLeaveList(leaveList);
  }

  return (
    <>
      <StyledTitle>
        {(userRoleId === 1 || userRoleId === 6) ? 'OVERALL ATTENDANCE' : 'DEPARTMENT ATTENDANCE'}
      </StyledTitle>
      <StyledChartContainer>
        <div className='name-title'>Today's attendance view:</div>
        <Spin spinning={displayReportLoader}>
          <Bar
            {...config}
            onReady={(plot) => {
              plot.on('plot:click', (evt) => {
                if (evt.data !== null) {
                  const { data: { data: { type, list, name }, color } } = evt;
                  setReportList(list)
                  setReportType(type);
                  setReportName(name)
                  if (userRoleId === 1 || userRoleId === 6) {
                    setReportColor(color);
                    toggleDepartmentAttendanceReportModal(true);
                  } else if (includes([2, 3], userRoleId)) {
                    if (type === 'Absent') {
                      setAttendanceReportTitle(`${type} staff`);
                    }
                    else {
                      setAttendanceReportTitle(`${type}- ${name}`);
                    }
                    toggleDisplayAttendanceStaffModal(true);
                  }
                }
              });
            }}
          />
        </Spin>
      </StyledChartContainer>
      <DepartmentAttendanceReport
        displayModal={displayDepartmentAttendanceReportModal}
        toggleModal={() => toggleDepartmentAttendanceReportModal(false)}
        reportType={reportType}
        reportColor={reportColor}
        list={reportList}
        name={reportName}
        lateListData={lateListData}
        onTimeList={onTimeList}
        leaveList={leaveList}
        absentList={absentList}
      />
      <AttendanceStaffList
        displayModal={displayAttendanceStaffModal}
        toggleModal={() => toggleDisplayAttendanceStaffModal(false)}
        title={attendanceReportTitle}
        reportType={reportType}
        list={reportList}
        name={reportName}
      />
    </>
  );
}

const mapStateToProps = state => ({
  userRoleId: getUserRoleId(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
});

export default connect(mapStateToProps, null)(AttendanceBarChart);

import React, { useEffect, useState } from "react";
import { Row, Col, Spin } from "antd";
import styled from "@emotion/styled";
import LeaveDonutChart from "./LeaveDonutChart";
import { LEAVE_ICON, LEAVE_INFO } from "common/Constants";
import COLORS from "common/Colors";
// Redux
import { connect } from "react-redux";
import {
  getUserRoleId,
  getLeaveCategoryList,
  getSelectedDepartmentId,
} from "redux/selectors";
import DepartmentLeaveBalance from "./DepartmentLeaveBalance";
import { find, get } from "lodash";
import { dateInDetail, getKenyanDateTime } from "utils/Date";
import { CALL_API } from "common/API";
import DepartmentStaffLeaveBalance from "./DepartmentStaffLeaveBalance";

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;

function LeaveBifurcationReport({
  userRoleId,
  leaveCategoryList,
  selectedDepartmentId,
}) {
  const [displayDepartmentLeaveModal, toggleDepartmentLeaveModal] = useState(false);
  const [staffDepartmentLeaveModal, toggleStaffDepartmentLeaveModal] = useState(false);
  const [selectedLeaveTypeId, setSelectedLeaveTypeId] = useState("");
  const [selectedLeaveType, setSelectedLeaveType] = useState("");
  const [displayLeaveReportLoader, toggleLeaveReportLoader] = useState(false);

  const [annualLeaveData, setAnnualLeaveData] = useState([
    {
      type: LEAVE_INFO.AVAILABLE,
      value: 0,
      color: COLORS.LEAVE.ANNUAL_AVAILABLE,
    },
    {
      type: LEAVE_INFO.CONSUMED,
      value: 0,
      color: COLORS.LEAVE.ANNUAL_CONSUMED,
    },
  ]);
  const [sickLeaveData, setSickLeaveData] = useState([
    {
      type: LEAVE_INFO.AVAILABLE,
      value: 0,
      color: COLORS.LEAVE.SICK_AVAILABLE,
    },
    { type: LEAVE_INFO.CONSUMED, value: 0, color: COLORS.LEAVE.SICK_CONSUMED },
  ]);

  useEffect(() => {
    if (
      (userRoleId !== 1 && selectedDepartmentId !== 0) ||
      (userRoleId !== 6 && selectedDepartmentId !== 0) ||
      userRoleId === 1 ||
      userRoleId === 6
    ) {
      getStatistics();
    }
  }, []);

  useEffect(() => {
    if (userRoleId !== 1 || userRoleId !== 6) {
      getStatistics();
    }
  }, [selectedDepartmentId]);

  const getStatistics = async () => {
    toggleLeaveReportLoader(true);
    const data = await CALL_API("leave-service", "post", {
      method: "overallTotalLeavesAvailableAndConsumed",
      parameters: {
        year: dateInDetail(getKenyanDateTime()).year,
        departmentId:
          userRoleId !== 1 && userRoleId !== 6 ? selectedDepartmentId : [""],
      },
    });
    let annualAvailable = 0;
    let annualConsumed = 0;
    let sickAvailable = 0;
    let sickConsumed = 0;
    data.map((list) => {
      // Note: first parameter is needed
      Object.entries(list).forEach(([key, value]) => {
        annualAvailable += value.annual.available;
        annualConsumed += value.annual.consumed;
        sickAvailable += value.sick.available;
        sickConsumed += value.sick.consumed;
      });
    });
    let dupeLeaveData = Array.from(annualLeaveData);
    dupeLeaveData[0].value = annualAvailable - annualConsumed;
    dupeLeaveData[1].value = annualConsumed;
    setAnnualLeaveData(dupeLeaveData);

    dupeLeaveData = Array.from(sickLeaveData);
    dupeLeaveData[0].value = sickAvailable - sickConsumed;
    dupeLeaveData[1].value = sickConsumed;
    setSickLeaveData(dupeLeaveData);
    toggleLeaveReportLoader(false);
  };

  const openDetailWindow = (leaveTypeId) => {
    const leaveName = get(
      find(leaveCategoryList, { leave_category_id: leaveTypeId }),
      "leave_category_name",
      ""
    );
    setSelectedLeaveTypeId(leaveTypeId);
    setSelectedLeaveType(`Department's ${leaveName} Balance`);
    if (userRoleId === 1 || userRoleId === 6) {
      toggleDepartmentLeaveModal(true);
    } else {
      toggleStaffDepartmentLeaveModal(true);
    }
  };

  return (
    <>
      <StyledTitle>
        {userRoleId === 1 || userRoleId === 6
          ? "OVERALL LEAVE REPORT"
          : "DEPARTMENT LEAVES"}
      </StyledTitle>
      <Row gutter={16}>
        <Col
          md={12}
          sm={24}
          xs={24}
          xl={24}
          onClick={() => openDetailWindow(7)}
        >
          <Spin spinning={displayLeaveReportLoader}>
            <LeaveDonutChart
              iconName={LEAVE_ICON[7]}
              title="ANNUAL LEAVE"
              data={annualLeaveData}
            />
          </Spin>
        </Col>
        <Col
          md={12}
          sm={24}
          xs={24}
          xl={24}
          onClick={() => openDetailWindow(1)}
        >
          <Spin spinning={displayLeaveReportLoader}>
            <LeaveDonutChart
              iconName={LEAVE_ICON[1]}
              title="SICK LEAVE"
              data={sickLeaveData}
            />
          </Spin>
        </Col>
      </Row>
      <DepartmentLeaveBalance
        displayModal={displayDepartmentLeaveModal}
        toggleModal={() => toggleDepartmentLeaveModal(false)}
        leaveTypeId={selectedLeaveTypeId}
        title={selectedLeaveType}
      />
      <DepartmentStaffLeaveBalance
        displayModal={staffDepartmentLeaveModal}
        toggleModal={() => toggleStaffDepartmentLeaveModal(false)}
        leaveTypeId={selectedLeaveTypeId}
        selectedDepartmentId={selectedDepartmentId}
        leaveName={selectedLeaveType}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  leaveCategoryList: getLeaveCategoryList(state),
});

export default connect(mapStateToProps, null)(LeaveBifurcationReport);

import React from 'react';
import { Modal, Table } from 'antd';
import styled from '@emotion/styled';
import COLORS from 'common/Colors';
import { dateInDetail } from 'utils/Date';

const StyledModal = styled(Modal)`
  width: 600px !important;
  
  .ant-modal-header {
    text-transform: uppercase;
    max-width: 95%;
  }
`;

const StyledTable = styled(Table)`
  .ant-table-content {
    overflow-x: auto;
    max-height:500px;
  }
  .ant-table-cell {
    padding: 10px;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK}
  }
  .non-white {
    background: #f9f9f9;
  }
`;

function LocationBasedAttendanceList({
  displayModal,
  toggleModal,
  title,
  list,
  reportType
}) {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      // width: '30%',
      // align: 'center'
    },
    // {
    //     title: 'Email',
    //     dataIndex: 'EmailId',
    //     key: 'EmailId',
    //     // width: '30%',
    // },
    {
      title: 'Department',
      dataIndex: 'department_name',
      key: 'department_name',
      // width: '50%',
      // align: 'center'
    },
  ];

  if (reportType === 'Other') {
    columns.push({
      title: <div>Location</div>,
      dataIndex: 'location',
      key: 'login_latitude',
      width: '50%',
      render: (_, record) => {
        const url1 ='http://maps.google.com/maps/?q=' + record.login_latitude + "," + record.login_longtitude + ''
        return (
          <div>
            {
              record.login_latitude !== '' && record.login_longtitude !== ''
              ? (
                <>
                  <div><a href={url1} target='_blank' style={{fontSize:'12px'}}>{record.login_latitude}, {record.login_longtitude}</a></div>
                </>
              )
              : '-'
            }
          </div>
        )
      }
    });
  } 

  return (
    <>
      <StyledModal
        title={title}
        visible={displayModal}
        onCancel={toggleModal}
        footer={null}
        destroyOnClose
      >
        <StyledTable
          dataSource={list}
          columns={columns}
          rowClassName={(record, index) => index % 2 === 0 ? '' :  'non-white'}
          rowKey='name'
          pagination={false} 
        />
      </StyledModal>
    </>
  );
}

export default LocationBasedAttendanceList;

import React, { useRef, useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Spin,
  Select,
  DatePicker,
  Space,
  Form,
  message,
  Modal,
  Input,
} from "antd";
import Button from "atoms/Button";
import moment from "moment";
import styled from "@emotion/styled";
import { CSVLink } from "react-csv";
// Redux
import { connect } from "react-redux";
import {
  getUserRoleId,
  getAllDepartmentList,
  getSelectedDepartmentId,
  getShiftTimeMaster,
} from "redux/selectors";

import { CALL_API } from "common/API";
import {
  MONTH_LIST,
  REPORT_DURATION_TYPES,
  STATUS_CODE,
} from "common/Constants";

import {
  addDaysinDate,
  dateInDetail,
  getKenyanDateTime,
  getMonthDateRange,
  getMySqlDate,
  momentDate,
  addMonthinDate,
} from "utils/Date";
import COLORS from "common/Colors";
import { concat, find, get, includes, orderBy, reduce } from "lodash";
import { NON_EMPTY_REGEX } from "common/Constants";
import ViewMore from "organisms/ViewMore";

const StyledTable = styled(Table)`
  height: 600px !important;
  padding-top: 20px;
  .ant-table-content {
    overflow-x: auto;
    max-height: 500px;
    white-space: nowrap;
  }
  table {
    width: 100% !important;
  }
  .ant-table-cell {
    padding: 20px;
  }
  .normal-column {
    min-width: 300px;
    white-space: normal;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
  }
  .non-white {
    background: #f9f9f9;
  }
  .stylediv {
    background: #f6f6f6 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000014;
    border-radius: 5px;
    opacity: 1;
    margin: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .styleleavediv {
    background: #5c86e6;
    color: white;
    box-shadow: 0px 3px 6px #00000014;
    border-radius: 5px;
    opacity: 1;
    margin: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    white-space: nowrap;
  }
  .stylebutton {
    background: #f6f6f6 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000014;
    border-radius: 5px;
    opacity: 1;
    border: none;
    margin: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    white-space: nowrap;
  }
`;

const StyledButton1 = styled(Button)`
  float: right;
  background: #5c86e6;
  color: white;
  box-shadow: 0px 3px 6px #00000014;
  border-radius: 5px;
  opacity: 1;
  :hover {
    background: #5c86e6;
    color: white;
  }
`;

const StyledSelect = styled(Select)`
  margin-right: 20px;
`;

const StyledRow = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: center;
  .leave_list_type{
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const StyledApprovalSearchForm = styled(Form)`
  .ant-form-item-with-help {
    margin-bottom: 0px;
    .ant-form-item-explain-connected {
      display: none;
    }
  }
  .ant-form-item {
    display: inline-block;
  }
  .outer-form {
    margin-right: 0px;
  }
  .ant-select-selector {
    max-width: 180px;
    min-width: 180px;
    margin-bottom: 10px;
    width: 180px;
  }
`;
const StyledButton3 = styled(Button)`
  width: 120px;
  border: 1px solid ${COLORS.PRIMARY};
  color: ${COLORS.PRIMARY};
  width: initial;
  background: transparent;
`;

function AbsentReport({ userRoleId, allDepartmentList, selectedDepartmentId }) {
  const [displayTableLoader, setDisplayTableLoader] = useState(false);
  const [response, setresponse] = useState([]);
  const [CSVabsentreportlist, setCSVabsentreportlist] = useState([]);
  const [form] = Form.useForm();
  const csvLinkEl = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [note, setnote] = useState("");
  const [recordlist, setrecordlist] = useState([]);
  const [ActionType, setActionType] = useState("1");
  const [leavetypeId, setlevetypeId] = useState(0);
  const { TextArea } = Input;
  const filterList = [];
  const [loader, showLoader] = useState(false);
  const [monthList, setMonthList] = useState([]);

  const curr_month = moment().month() + 1;

  const list = () => {
    let month_list = [];
    for (let i = 0; i < curr_month; i++) {
      month_list.push(MONTH_LIST[i]);
    }
    setMonthList(month_list);
  };

  const STAFF_LIST_ALL = response.map((val) => {
    return { user_id: val.employeeId, name: val.name };
  });

  for (let i = 0; i < STAFF_LIST_ALL.length; i++) {
    filterList.push(STAFF_LIST_ALL[i]);
  }

  const STAFF_LIST = [...new Set(filterList.map((a) => JSON.stringify(a)))].map(
    (a) => JSON.parse(a)
  );

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.setFieldsValue({ description: "" });
  };

  const leaveclick = (record, leavetype) => {
    showModal();
    setrecordlist(record);
    setlevetypeId(leavetype);
  };

  // Function for update Leave API
  const update = async () => {
    showLoader(true);
    const { code } = await CALL_API("absent-leave", "post", {
      employeeId: recordlist.employeeId,
      date: recordlist.date,
      leave_type: leavetypeId,
      user_absent_action_id: recordlist.userabsent_action,
      reason: note,
      department_id: recordlist.department_id,
    });
    if (code === STATUS_CODE.LEAVE_EXCEED) {
      message.error("LEAVE_EXCEED");
    } else {
      message.success("leave has been updated");
    }
    showLoader(false);
    setIsModalOpen(false);
    form.setFieldsValue({ description: "" });
    getAttendanceReport();
  };

  const CSVHeaders = [
    { label: "Employee ID" },
    { label: "Name" },
    { label: "Department" },
    { label: "Date" },
    { label: "LeaveId" },
    { label: "Reason" },
  ];

  const columns = [
    {
      title: "Employee ID",
      dataIndex: "employeeId",
      key: "employeeId",
      width: "3%",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "3%",
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      width: "5%",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "5%",
      render: (date) => {
        const dateFormat = moment(date).format("DD MMM YYYY");
        return `${dateFormat}`;
      },
    },
    {
      title: "Day",
      dataIndex: "date",
      key: "date",
      width: "5%",
      render: (date) => {
        const getdayAlone = moment(date).format("dddd");
        return `${getdayAlone}`;
      },
    },
    {
      title: "Validate",
      dataIndex: "leave_category_id",
      key: "leave_category_id",
      width: "5%",
      align: "center",
      render: (_, record) => {
        return (
          <StyledRow >
            {record.status_id === 1 ? (
              <StyledRow  >
                <div  className="leave_list_type">
                  <Col>
                    <button
                      className="stylebutton"
                      onClick={() => leaveclick(record, 1)}
                    >
                      Sick Leave
                    </button>{" "}
                  </Col>
                  <Col>
                    <button
                      className="stylebutton"
                      onClick={() => leaveclick(record, 7)}
                    >
                      Annual Leave
                    </button>
                  </Col>
                  <Col>
                    <button
                      className="stylebutton"
                      onClick={() => leaveclick(record, 10)}
                    >
                      Others
                    </button>
                  </Col>
                </div>
              </StyledRow>
            ) : (
              <StyledRow >
                <Col>
                  {" "}
                  <div className="styleleavediv">
                    {record?.leave_category_id === 1
                      ? "Sick Leave"
                      : record?.leave_category_id === 7
                      ? "Annual Leave"
                      : "Other Leave"}
                  </div>{" "}
                </Col>
              </StyledRow>
            )}
          </StyledRow>
        );
      },
    },
  ];
  if (ActionType === "2") {
    columns.push({
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      width: "5%",
      className: "normal-column",
      render: (reason) => {
        return (
          <>
            <ViewMore value={reason} />
          </>
        );
      },
    });
  }

  const data1 = response.filter((val) => {
    if (JSON.stringify(val?.status_id).includes(ActionType)) {
      if (form.getFieldValue("staff_name") === "") {
        return val;
      } else if (val?.employeeId === form.getFieldValue("staff_name")) {
        return val;
      }
    }
  });

  useEffect(() => {
    list();
    getAttendanceReport();
  }, [
    selectedDepartmentId,
    userRoleId,
    form.getFieldValue("duration_type"),
    ActionType,
    form.getFieldValue("end_date"),
    form.getFieldValue("month"),
    form.getFieldValue("start_date"),
    form.getFieldValue("departmentId"),
  ]);

  // Function to generate report
  const getAttendanceReport = async () => {
    return new Promise(async (resolve, reject) => {
      setresponse([]);
      const { start_date, end_date, duration_type, month, departmentId } =
        form.getFieldsValue();
      setDisplayTableLoader(true);
      const { startDateOfMonth, lastDateOfMonth } = getMonthDateRange(
        dateInDetail(getKenyanDateTime()).year,
        get(find(MONTH_LIST, { value: month }), "index", 0)
      );
      const { code, absenteeAndLeaveList = [] } = await CALL_API(
        "absent-report",
        "post",
        {
          departmentId:
            userRoleId === 1 || userRoleId === 6
              ? [departmentId]
              : selectedDepartmentId,
          start_date: getMySqlDate(
            duration_type === 2 ? startDateOfMonth : start_date
          ),
          end_date: getMySqlDate(
            duration_type === 2 ? lastDateOfMonth : end_date
          ),
        }
      );
      setDisplayTableLoader(false);
      if (includes([STATUS_CODE.SUCCESS, STATUS_CODE.RECORD_NOT_FOUND], code)) {
        let recordList = [];
        absenteeAndLeaveList.map((list) => {
         // note: first parameter is needed
          Object.entries(list).forEach(([key, value]) => {
            recordList = concat(recordList, value);
          });
        });
        setresponse(orderBy(recordList, ["date"], ["desc"]));
      }
    });
  };

  // Function to validate the date range
  const validateDateRange = (date) => {
    const endDate = moment(form.getFieldValue("end_date"));
    if (endDate < date) {
      form.setFieldsValue({ end_date: date });
    }
  };

  // Function to create CSV file 
  const prepareCSV = async () => {
    if (data1.length > 0) {
      if (ActionType === "2") {
        setCSVabsentreportlist(
          reduce(
            data1,
            function (result, record) {
              const {
                date_th: startDate_th,
                shortMonthName: startMonthName,
                year: end_year,
              } = dateInDetail(record.date);
              result.push({
                "Employee ID": record.employeeId,
                "Name": record.name,
                "Department": record.department,
                "Date": `${startDate_th} ${startMonthName} ${end_year}`,
                "Day": `${moment(record?.date).format("dddd")}`,
                "LeaveId":
                  record?.status_id === 2
                    ? record?.leave_category_id === 1
                      ? "Sick Leave"
                      : record?.leave_category_id === 7
                      ? "Annual Leave"
                      : "Others"
                    : "-",
                "Reason": record?.status_id === 2 ? record?.reason : "-",
              });
              return result;
            },
            []
          )
        );
        setTimeout(() => {
          csvLinkEl.current.link.click();
        });
      } else {
        setCSVabsentreportlist(
          reduce(
            data1,
            function (result, record) {
              const {
                year: end_year,
                date_th: startDate_th,
                shortMonthName: startMonthName,
              } = dateInDetail(record.date);
              result.push({
                "Employee ID": record.employeeId,
                "Name": record.name,
                "Department": record.department,
                "Date": `${startDate_th} ${startMonthName} ${end_year}`,
                "Day": `${moment(record?.date).format("dddd")}`,
              });
              return result;
            },
            []
          )
        );
        setTimeout(() => {
          csvLinkEl.current.link.click();
        });
      }
    } else {
      message.error(`No data available to download.`);
    }
  };

  const paginationOptions = {
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    pageSize: 20,
    showSizeChanger: false,
  };

  return (
    <>
      <Row className="page-title">
        <Col xs={24} sm={19} md={19} className="page-title-head">
          <Space>
            <StyledApprovalSearchForm
              form={form}
              name="approval_form"
              layout="inline"
              initialValues={{
                staff_name: "",
                duration_type: 2,
                Action_type: 2,
                month: dateInDetail(getKenyanDateTime()).monthName,
                start_date: momentDate(addMonthinDate(getKenyanDateTime(), -1)),
                end_date: momentDate(addDaysinDate(getKenyanDateTime())),
                departmentId: "",
              }}
              onFinish={getAttendanceReport}
            >
              <Form.Item
                name="duration_type"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  placeholder="Select..."
                  options={REPORT_DURATION_TYPES}
                />
              </Form.Item>
              <StyledSelect
                className="selectbox"
                placeholder="Employee type"
                defaultValue={"1"}
                options={[
                  {
                    value: "1",
                    label: "Waiting For Action",
                  },
                  {
                    value: "2",
                    label: "Action history",
                  },
                ]}
                onChange={(e) => setActionType(e)}
              />

              <Form.Item className="outer-form" shouldUpdate>
                {({ getFieldValue }) => {
                  const duration_type = getFieldValue("duration_type");
                  return duration_type === 1 ? (
                    <>
                      <Form.Item
                        name="start_date"
                        rules={[
                          () => ({
                            // note: first parameter is needed
                            validator(rule, value) {
                              const date = moment(value);
                              return date.isValid()
                                ? Promise.resolve()
                                : Promise.reject();
                            },
                          }),
                        ]}
                      >
                        <DatePicker
                          placeholder="Start date"
                          onChange={validateDateRange}
                          allowClear={false}
                        />
                      </Form.Item>

                      <Form.Item
                        name="end_date"
                        rules={[
                          () => ({
                            // note: first parameter is needed
                            validator(rule, value) {
                              const date = moment(value);
                              return date.isValid()
                                ? Promise.resolve()
                                : Promise.reject();
                            },
                          }),
                        ]}
                      >
                        <DatePicker
                          placeholder="End date"
                          onChange={validateDateRange}
                          allowClear={false}
                        />
                      </Form.Item>
                    </>
                  ) : (
                    <Form.Item
                      name="month"
                      rules={[
                        {
                          required: true,
                          message: "",
                        },
                      ]}
                    >
                      <Select placeholder="Select..." options={monthList} />
                    </Form.Item>
                  );
                }}
              </Form.Item>
              <Form.Item
                name="staff_name"
                rules={[
                  {
                    required: false,
                    message: "",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.name ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.name ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.name ?? "").toLowerCase())
                  }
                  placeholder="Select Employee"
                  options={concat(
                    {
                      user_id: "",
                      name: "All Employee",
                    },
                    STAFF_LIST
                  )}
                  fieldNames={{
                    label: "name",
                    value: "user_id",
                  }}
                />
              </Form.Item>
              {(userRoleId === 1 || userRoleId === 6) && (
                <Form.Item name="departmentId">
                  <Select
                    placeholder="Select Department"
                    options={concat(
                      {
                        department_id: "",
                        department_name: "All Departments",
                      },
                      allDepartmentList
                    )}
                    fieldNames={{
                      label: "department_name",
                      value: "department_id",
                    }}
                  />
                </Form.Item>
              )}
            </StyledApprovalSearchForm>
          </Space>
        </Col>
        <Col xs={24} sm={4} md={4} className="align-right">
          <StyledButton3
            onClick={prepareCSV}
            disabled={response?.length === (0 || null || undefined)}
          >
            Export CSV file
          </StyledButton3>
          <CSVLink
            header={CSVHeaders}
            data={CSVabsentreportlist}
            filename={"absent-report.csv"}
            asyncOnClick={true}
            ref={csvLinkEl}
          />
        </Col>
      </Row>
      <Spin spinning={displayTableLoader}>
        <StyledTable
          dataSource={data1}
          columns={columns}
          // note: first parameter is needed
          rowClassName={(record, index) => (index % 2 === 0 ? "" : "non-white")}
          rowKey="checkin_id"
          pagination={data1.length > 20 ? paginationOptions : false}
        />
      </Spin>
      <Modal
        title="Add Note"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
        keyboard={false}
      >
        <Form
          form={form}
          name="leaveform"
          layout="vertical"
          initialValues={{
            description: "",
          }}
          onFinish={update}
        >
          <Form.Item
            label="Your message:"
            name="description"
            rules={[
              {
                required: true,
                pattern: NON_EMPTY_REGEX,
                message: "Please enter reason!",
              },
            ]}
          >
            <TextArea
              rows={4}
              maxLength={500}
              onChange={(e) => setnote(e.target.value)}
            />
          </Form.Item>
          <StyledButton1 type="text" loading={loader} htmlType="submit">
            Save
          </StyledButton1>
          <br></br>
          <br></br>
        </Form>
      </Modal>
    </>
  );
}


const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  allDepartmentList: getAllDepartmentList(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  shiftTimeMaster: getShiftTimeMaster(state),
});

export default connect(mapStateToProps, null)(AbsentReport);

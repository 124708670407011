import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Select, Button, Modal } from 'antd';
import moment from 'moment';
// Redux
import { connect } from 'react-redux';
import {
	getLocations,
	getIsOnBreak,
	getIsCheckedIn,
	getCheckInLocationId,
	getPunchId,
	getBreakId,
	getShiftTimeMaster,
	getTime24,
	getDepartmentList,
	getUserInfo,
	getLongDay
} from 'redux/selectors';
import { bindActionCreators } from 'redux';
import {
	setIsOnBreak,
	toggleSuccessModal,
	setCheckedIn,
	setCheckInLocationId,
	toggleProcessingModal,
	setPunchId,
	setBreakId,
} from 'redux/actions';
import styled from "@emotion/styled";
// Common
import COLORS from 'common/Colors';

import CustomIcon from 'atoms/Icon';
import BreakModal from 'organisms/BreakModal';
import CheckInProblemModal from 'organisms/CheckInProblemModal';
import { displayErrorModal } from 'common/common';
import { PUNCH_ACTIONS, STATUS_CODE } from 'common/Constants';
import { CALL_API } from 'common/API';
import { concat, find, get, includes, size, result, orderBy } from 'lodash';
import { DEVICE } from 'common/Device';
import { dateInDetail, getMySqlDate, getKenyanDateTime } from 'utils/Date';
import { Input } from 'antd';

const StyleContainerRow = styled(Row)`
    background: transparent linear-gradient(98deg, #F2F6FF -30%, ${COLORS.PRIMARY} 100%) 0% 0% no-repeat padding-box;;
    margin-top: 1rem;
    border-radius: 5px;
    padding: 7px 15px;
`;

const StyledLocationSelect = styled(Select)`
	padding-top: 5px;
	padding-bottom: 5px;
	margin-right: 15px;
	width: 200px;
	@media ${DEVICE.tablet}  {
        width: initial;
    }
`

const StyledCheckInCheckOutButton = styled(Button)`
	margin-left: 5px;
	margin-right: 5px;
	border-radius: 5px;
	@media ${DEVICE.mobileS}  {
		margin-left: 0px
  }
`

const StyledStartBreak = styled(Button)`
	margin-right: 20px;
	font-size: 1rem;
	vertical-align: text-top;
    margin-top: 5px;
	svg {
		margin-left: 8px;
    	vertical-align: top;
	}
`

function CheckInCheckout({
	locationList,
	isOnBreak,
	isCheckedIn,
	updateIsOnBreak,
	updateSuccessModal,
	updateCheckedIn,
	updateCheckInLocationId,
	checkInLocationId,
	updateProcessingModal,
	punchId,
	breakId,
	savePunchId,
	saveBreakId,
	shiftTimeMaster,
	systemTime,
	departmentList,
	userInfo,
	detaildate
}) {
	const [isBreakModalVisible, toggleDisplayBreakModal] = useState(false);
	const [isCheckInProblemModalVisible, toggleCheckInProblemModal] = useState(false);
	const [isCheckInReminderModalVisible, toggleCheckInReminderModal] = useState(false);
	const [locationId, setLocationId] = useState(0);
	const [showCheckInOutLoader, toggleCheckInOutLoader] = useState(false);
	const [showLateCheckInModal, setShowLateCheckInModal] = useState(false);
	const [punchInOutReason, setPunchInOutReason] = useState('');
	const [reasonValidation, setReasonValidation] = useState(false)
	const containerRef = useRef(null);
	const { TextArea } = Input;

	useEffect(() => {
		setLocationId(checkInLocationId);
	}, [checkInLocationId]);

	const getPunchInOutReason = (action) => {
		if (punchInOutReason != "") {
			setShowLateCheckInModal(false);
			setReasonValidation(false);
			punchInOutApiCal(action, ""); 
		} else {
			setReasonValidation(true);
		}
	};
	const handlePunchInOutReason = (e) => {
		setPunchInOutReason(e.target.value);
		if (e.target.value.length > 1) {
			setReasonValidation(false);
		}
	}
	const hideModal = () => {
		setShowLateCheckInModal(false);
	};
	const startBreak = (breakTypeId) => {
		toggleDisplayBreakModal(false);
		punchInOut(PUNCH_ACTIONS.BREAK_START, breakTypeId);
	}

	const getCoordinates = async () => {
		return new Promise((resolve) => {
			try {
				if (navigator.userAgent === 'application') {
					const latitude = window.localStorage.getItem('latitude');
					const longitude = window.localStorage.getItem('longitude');
					if (latitude === null || longitude === null) {
						displayErrorModal('Please allow geolocation to read your browser location for login/logout.');
						resolve({
							latitude: '',
							longitude: ''
						});
						window.Print.postMessage("access-location");
					} else if (latitude && longitude) {
						resolve({
							latitude,
							longitude
						});
					} else {
						displayErrorModal('Please allow geolocation to read your browser location for login/logout.');
						resolve({
							latitude: '',
							longitude: ''
						});
						window.Print.postMessage("access-location");
					}
				} else {
					navigator.geolocation.getCurrentPosition(function (location) {
						resolve({
							latitude: location.coords.latitude,
							longitude: location.coords.longitude
						});
					},
						function (error) {
							if (error.code === error.PERMISSION_DENIED) {
								displayErrorModal('Please allow geolocation to read your browser location for login/logout.');
								resolve({
									latitude: '',
									longitude: ''
								});
							} else {
								displayErrorModal('Unable to read browser location.');
								resolve({
									latitude: '',
									longitude: ''
								});
							}
						});
				}
			} catch (err) {
				displayErrorModal('Unable to read browser location.');
				resolve({
					latitude: '',
					longitude: ''
				});
			}
		})
	}

	const punchInOutApiCal = async (action, breakTypeId) => {

		const loginFromAnywhere = get(find(locationList, { location_id: locationId }), 'login_from_anywhere', 0);
		const isPunchInOut = includes([PUNCH_ACTIONS.PUNCH_IN, PUNCH_ACTIONS.PUNCH_OUT], action);
		if (isPunchInOut) {
			toggleCheckInOutLoader(true);
			var {
				latitude = '',
				longitude = ''
			} = await getCoordinates();
		} else {
			updateProcessingModal(true);
		}

		if ((isPunchInOut && latitude !== '' && longitude !== '') || !isPunchInOut) {
			const locationName = locationList.find((loc) => loc.location_id === locationId);
			const deptName = result(find(departmentList, (e) => { return e.department_id === get(userInfo, 'staff_department_id', '') }), 'department_name')
			const department_ID = get(userInfo, 'staff_department_id', '')
			const {
				code,
				maxProximity = '',
				punchInsertedId = 0
			} = await CALL_API('punch-in-out', 'post', {
				break_id: breakTypeId,
				punch_action_id: action,
				location_id: locationId,
				location_name: locationName?.location_name,
				department_name: deptName,
				department_id: department_ID,
				employee_name: userInfo?.name,
				employee_id: userInfo?.employer_id,
				punchId,
				breakId,
				latitude,
				longitude,
				skipDistanceChecking: loginFromAnywhere ? true : false,
				reason: punchInOutReason
			});

			updateProcessingModal(false);
			if (code === STATUS_CODE.PUNCH_IN_SUCCESSFUL) {
				updateCheckedIn(true);
				updateCheckInLocationId(locationId);
				updateSuccessModal({
					visibility: true,
					message: `You have been checked in successfully`
				});
				savePunchId(punchInsertedId);
			} else if (code === STATUS_CODE.PUNCH_OUT_SUCCESSFUL) {
				updateCheckedIn(false);
				updateCheckInLocationId('');
				updateSuccessModal({
					visibility: true,
					message: `You have been checked out successfully`
				});
				savePunchId('');
			} else if (code === STATUS_CODE.BREAK_STARTED) {
				updateIsOnBreak(true);
				updateSuccessModal({
					visibility: true,
					message: 'Break has been started'
				});
				updateProcessingModal(false);
				saveBreakId(punchInsertedId);
			} else if (code === STATUS_CODE.BREAK_ENDED) {
				updateIsOnBreak(false);
				updateSuccessModal({
					visibility: true,
					message: 'Your break has been stopped'
				});
				updateProcessingModal(false);
				saveBreakId('');
			} else if (code === STATUS_CODE.RECORD_EXIST) {
				if (isPunchInOut) {
					displayErrorModal(`You are already logged ${action === PUNCH_ACTIONS.PUNCH_IN ? 'in' : 'out'}.`);
				} else {
					displayErrorModal(`You are already on break`);
				}
			} else if (code === STATUS_CODE.OUTSIDE_ALLOWED_PROXIMITY) {
				displayErrorModal(`You are far from allowed proximity (${maxProximity} meters). Please try to get closer.`);
			} else if (code === STATUS_CODE.INVALID_PAYLOAD) {
				toggleCheckInProblemModal(true);
			} else toggleCheckInProblemModal(true);
			toggleCheckInOutLoader(false);
		}
	}
	const punchInOut = async (action, breakTypeId) => {
		// Get the day of the week and current time
		const dateArray = detaildate.split(',');
		const dayOfWeek = dateArray[0].trim();
		const [hours, minutes] = systemTime.split(':').map(Number);
		// Show an error message if no location is selected
		if (!locationId) {
			toggleCheckInOutLoader(false);
			displayErrorModal('Select location to check-in');
			return;
		}

		// Check for late check-in
		if ((hours >= 8 && minutes >= 5 && !isCheckedIn) || (hours > 8 && !isCheckedIn)) {
			setPunchInOutReason("");
			setShowLateCheckInModal(true);
			setReasonValidation(false);
		}
		// Check for early check-out
		else if (dayOfWeek === 'Saturday' && hours <= 13 && ( hours == 13 ? minutes <= 0 : true ) && !breakTypeId && isCheckedIn) {
			setPunchInOutReason("");
			setShowLateCheckInModal(true);
			setReasonValidation(false);
		}
		else if (hours <= 17 && ( hours == 17 ? minutes <= 0 : true ) && !breakTypeId && isCheckedIn && dayOfWeek != 'Saturday') {
			setShowLateCheckInModal(true);
			setReasonValidation(false);
			setPunchInOutReason("");
		}
		else {
			setShowLateCheckInModal(false);
			punchInOutApiCal(action, breakTypeId);
		}
		toggleCheckInOutLoader(false);
	};

	const stopBreak = () => {
		Modal.confirm({
			icon: '',
			content: 'Are you sure you want to stop the break',
			okText: 'YES',
			cancelText: 'No',
			onOk: () => {
				punchInOut(PUNCH_ACTIONS.BREAK_END, breakId);
			}
		});
	}

	if (size(shiftTimeMaster)) {
		if (!punchId && !isCheckInReminderModalVisible && window.localStorage.getItem('login_reminder') !== 'true') {
			const kenyaDateTime = getKenyanDateTime();
			const dayIndex = dateInDetail(kenyaDateTime).day_index + 1;
			const { start_time, end_time } = find(shiftTimeMaster, { day: dayIndex });
			if (start_time && end_time) {
				const dayStartTime = moment(`${getMySqlDate(kenyaDateTime)} ${start_time}:00`);
				const dayEndTime = moment(`${getMySqlDate(kenyaDateTime)} ${end_time}:00`);
				const actualCheckInDateTime = moment(kenyaDateTime);
				if (actualCheckInDateTime < dayEndTime) {
					const diff = actualCheckInDateTime.diff(dayStartTime, 'minutes');
					if (diff) {
						toggleCheckInReminderModal(true);
					}
				}
			}
		}
	}

	return (
		<>
			<StyleContainerRow >
				<Col span={24}>
					<div>Location*</div>
				</Col>
				<Col md={16} sm={24} xs={24} ref={containerRef}>
					<StyledLocationSelect
						getPopupContainer={() => containerRef.current}
						placeholder="Select Location"
						options={concat({
							location_id: '',
							location_name: 'Select Location'
						}, orderBy(locationList, [loc => loc.location_name.toLowerCase()], ['asc']))}
						fieldNames={{
							label: 'location_name',
							value: 'location_id'
						}}
						value={locationId}
						onChange={val => setLocationId(val)}
					/>
					{
						!isCheckedIn && (
							<StyledCheckInCheckOutButton
								onClick={() => punchInOut(PUNCH_ACTIONS.PUNCH_IN, '')}
								loading={showCheckInOutLoader}
							>
								Check-In
							</StyledCheckInCheckOutButton>
						)
					}
					{
						isCheckedIn && !isOnBreak && (
							<StyledCheckInCheckOutButton
								onClick={() => punchInOut(PUNCH_ACTIONS.PUNCH_OUT, '')}
								loading={showCheckInOutLoader}
							>
								Check-Out
							</StyledCheckInCheckOutButton>
						)
					}
				</Col>
				<Col md={8} sm={12} xs={12} className='align-right'>
					{
						isCheckedIn && (
							<StyledStartBreak
								type="text"
								onClick={() => isOnBreak ? stopBreak() : toggleDisplayBreakModal(true)}
							>
								{isOnBreak ? 'Stop Break' : 'Start Break'}
								<CustomIcon name={isOnBreak ? 'stop_break' : 'play'} />
							</StyledStartBreak>
						)
					}
				</Col>
				<BreakModal
					isModalVisible={isBreakModalVisible}
					closeModal={() => toggleDisplayBreakModal(!isBreakModalVisible)}
					startBreak={(breakTypeId) => { console.log("breakTypeId in BreakModal", breakTypeId); startBreak(breakTypeId); }}
				/>
				<CheckInProblemModal
					isModalVisible={isCheckInProblemModalVisible}
					closeModal={() => toggleCheckInProblemModal(!isCheckInProblemModalVisible)}
					tryAgain={() => punchInOut(PUNCH_ACTIONS.PUNCH_IN, '')}
				/>

				<Modal
					title=""
					open={showLateCheckInModal}
					footer={false}
					onCancel={hideModal}
					okText="Submit"
					cancelText="Cancel"
					maskClosable={false}
				>
					<div className="mt-3 mb-1">Reason For {(!isCheckedIn) ? "Late Check-in" : "Early Check-out"}</div>
					<TextArea rows={4} name="punchInOutReason" value={punchInOutReason} onChange={handlePunchInOutReason} />
					{reasonValidation && <div className="text-danger">*Reason is required</div>}
					<div className="mt-3 d-block">
						<div className="d-flex justify-content-end">
							<Button onClick={hideModal}>Cancel</Button>

							<Button type="primary" onClick={() => getPunchInOutReason((!isCheckedIn ? PUNCH_ACTIONS.PUNCH_IN : PUNCH_ACTIONS.PUNCH_OUT))} className="mx-2">Submit</Button>
						</div>
					</div>
				</Modal>
			</StyleContainerRow>
		</>
	);
}

const mapStateToProps = state => ({
	departmentList: getDepartmentList(state),
	locationList: getLocations(state),
	isOnBreak: getIsOnBreak(state),
	isCheckedIn: getIsCheckedIn(state),
	checkInLocationId: getCheckInLocationId(state),
	punchId: getPunchId(state),
	breakId: getBreakId(state),
	shiftTimeMaster: getShiftTimeMaster(state),
	systemTime: getTime24(state),
	userInfo: getUserInfo(state),
	detaildate: getLongDay(state)
});

const mapDispatchToProps = dispatch => bindActionCreators(
	{
		updateIsOnBreak: setIsOnBreak,
		updateCheckedIn: setCheckedIn,
		updateSuccessModal: toggleSuccessModal,
		updateCheckInLocationId: setCheckInLocationId,
		updateProcessingModal: toggleProcessingModal,
		savePunchId: setPunchId,
		saveBreakId: setBreakId,
	},
	dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(CheckInCheckout);

import React, { useEffect, useState, useRef } from 'react';
import { Column  } from '@ant-design/plots';
import styled from '@emotion/styled';
import COLORS from 'common/Colors';
import { Spin } from 'antd';
import { concat, includes, isNaN, map, max, size, forEach, has, unionBy, find, mergeWith} from 'lodash';
import { DatePicker, Space } from 'antd';
import { dateInDetail, getKenyanDateTime } from 'utils/Date';
import { CALL_API } from 'common/API';
import moment from 'moment';

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
  .header{
    display:flex;
    justify-content: space-between;
  }
`;

const StyledChartContainer = styled.div`
  height:380px;
  border: solid 1px #9B9B9B;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 25px;
  .name-title {
    display:flex;
    justify-content:space-between;
    padding: 3px 10px;
    font-size: 1rem;
    padding-bottom: 15px;
  }
`;

function EmployeeAttendanceAnalysis({}) {
  const [ displayReportLoader, toggleReportLoader ] = useState(false);
  const [ reportData, setReportData ] = useState([
    {
        month:'Jan',
        type: 'Present',
        count: 0,
    },
    {
        month:'Feb',
        type: 'Present',
        count: 0,
    },
    {
        month:'Mar',
        type: 'Present',
        count: 0,
    },
    {
        month:'Apr',
        type: 'Present',
        count: 0,
    },
    {
        month:'May',
        type: 'Present',
        count: 0,
    },
    {
        month:'Jun',
        type: 'Present',
        count: 0,
    },
    {
        month:'Jul',
        type: 'Present',
        count: 0,
    },
    {
        month:'Aug',
        type: 'Present',
        count: 0,
    },
    {
        month:'Sep',
        type: 'Present',
        count: 0,
    },
    {
        month:'Oct',
        type:'Present',
        count: 0,
    },
    {
        month:'Nov',
        type:'Present',
        count: 0,
    },
    {
        month:'Dec',
        type: 'Present',
        count: 0,
    },
    {
      month:'Jan',
      type: 'Absent',
      count: 0,
  },
  {
      month:'Feb',
      type: 'Absent',
      count: 0,
  },
  {
      month:'Mar',
      type: 'Absent',
      count: 0,
  },
  {
      month:'Apr',
      type: 'Absent',
      count: 0,
  },
  {
      month:'May',
      type: 'Absent',
      count: 0,
  },
  {
      month:'Jun',
      type: 'Absent',
      count: 0,
  },
  {
      month:'Jul',
      type: 'Absent',
      count: 0,
  },
  {
      month:'Aug',
      type: 'Absent',
      count: 0,
  },
  {
      month:'Sep',
      type: 'Absent',
      count: 0,
  },
  {
      month:'Oct',
      type:'Absent',
      count: 0,
  },
  {
      month:'Nov',
      type:'Absent',
      count: 0,
  },
  {
      month:'Dec',
      type: 'Absent',
      count: 0,
  },
  {
    month:'Jan',
    type: 'OnLeave',
    count: 0,
},
{
    month:'Feb',
    type: 'OnLeave',
    count: 0,
},
{
    month:'Mar',
    type: 'OnLeave',
    count: 0,
},
{
    month:'Apr',
    type: 'OnLeave',
    count: 0,
},
{
    month:'May',
    type: 'OnLeave',
    count: 0,
},
{
    month:'Jun',
    type: 'OnLeave',
    count: 0,
},
{
    month:'Jul',
    type: 'OnLeave',
    count: 0,
},
{
    month:'Aug',
    type: 'OnLeave',
    count: 0,
},
{
    month:'Sep',
    type: 'OnLeave',
    count: 0,
},
{
    month:'Oct',
    type:'OnLeave',
    count: 0,
},
{
    month:'Nov',
    type:'OnLeave',
    count: 0,
},
{
    month:'Dec',
    type: 'OnLeave',
    count: 0,
},
  ]);
  const config = {
    data:reportData,
    isStack: true,
    xField: 'month',
    yField: 'count',
    seriesField: 'type',
    height:300,
    label: {
      position: '',
      content:''
    },
    legend: {
      layout: 'horizontal',
      position: 'top'
    },
    interactions: [
      {
        type: 'active-region',
        enable: false,
      },
    ],
    yAxis: {
        // max: isNaN(max(map(reportData, 'count'))) ? 100 : max(map(reportData, 'count')) + 15,
        grid: {
          line: {
            style: {
              opacity: 0
            }
          }
        }
    },
    xAxis: {
      label: {
        formatter: (value) => value.slice(0, 3),
        offset: 10,
        autoHide: false,
        autoRotate: true,
      },
      
    },
    }
    const defaultYear = moment().year();

    useEffect(() => { 
      getData(moment(`${defaultYear}`, 'YYYY'));
    }, []);
  
    const [yearSelected, setYearSelected] = useState(null)
    const [total, setTotal] = useState(0)
    const containerRef = useRef(null);
   
    const getData = async (year) =>{
      const yearString = year.format('YYYY');
      setYearSelected(yearString)
      toggleReportLoader(true)
      const { 
        employeeAttendanceAnalysisPresentData, 
        employeeAttendanceAnalysisAbsentData,
        employeeAttendanceAnalysisOnleaveData
       } = await CALL_API('admin-dashboard', 'post', {
        method: 'employeeAttendanceAnalysis',
        parameters: {
          year: yearString,
        },
      });
      const data = concat( employeeAttendanceAnalysisPresentData, employeeAttendanceAnalysisAbsentData,  employeeAttendanceAnalysisOnleaveData)
      var  reportData1 = []
      forEach(data, function(obj) {
        var month = obj.Month.slice(0, 3);
        if (has(obj, 'Present')) {
          reportData1.push({ month: month, type: 'Present', count: obj.Present });
        }
        if (has(obj, 'Absent')) {
          reportData1.push({ month: month, type: 'Absent', count: obj.Absent });
        }
        if (has(obj, 'onLeave')) {
          reportData1.push({ month: month, type: 'OnLeave', count: obj.onLeave });
        }
      });
      setReportData(prevData => {
        const mergedData = unionBy(prevData, reportData1, (item) => item.month + item.type);
        mergedData.forEach(item => {
          const count = (find(reportData1, {  'month': item.month, 'type': item.type  }) || {}).count || 0;
          item.count = count;
        });
       
        return mergedData;
      });
      toggleReportLoader(false)
  
    }
  
  return (
    <>      
      <StyledTitle>
         <div className='header' ref={containerRef}>
                 Employee Attendance Analysis
                 <DatePicker.YearPicker  getPopupContainer={() => containerRef.current} defaultValue={moment(`${defaultYear}`, 'YYYY')} clearIcon={null} onChange={getData} />
          </div>    
      </StyledTitle>
      <StyledChartContainer>
      <div className='name-title'>
          <p style={{color:'blue'}}>Year : {yearSelected}</p>
      </div>
        <Spin spinning={displayReportLoader}>
          <Column 
            {...config}
             />
        </Spin>
      </StyledChartContainer>
    </>
  );
}


export default EmployeeAttendanceAnalysis

import React from "react";
import styled from "@emotion/styled";

const StyledDiv = styled.div`
  margin-top: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  h4 {
    font-size: 18px;
  }
`;

function NotFound() {
  return (
    <StyledDiv>
      <h4>Sorry! You don't have permission to view this page </h4>
    </StyledDiv>
  );
}

export default NotFound;

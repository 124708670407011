import React, { useEffect, useState } from 'react';
import { Row, Col, Spin } from 'antd';
import { Pie } from '@ant-design/plots';

// Redux
import { connect } from 'react-redux';
import { getUserName, getTime, getAMPM, getLongDay } from 'redux/selectors';
import styled from '@emotion/styled';

import RoundIcon from 'atoms/RoundIcon';
import { CALL_API } from 'common/API';
import CustomIcon from 'atoms/Icon';
import COLORS from 'common/Colors';
import { dateInDetail } from 'utils/Date';
import { STATUS_CODE } from 'common/Constants';

const StyledRowContainer = styled(Row)`
  padding: 25px;
  .chart-container {
    padding-top: 5rem;
  }
`;

const StyledTitle = styled.div`
  display: inline-block;
  vertical-align: top;
  padding: 8px 10px;
  font-size: 1rem;
  font-weight: bold;
  color: ${COLORS.SECONDARY_BLACK};
  overflow-x: hidden;
  white-space: pre;
  text-overflow: ellipsis;
`;

function StaffAnnualLeave() {
  const [ availableLeaves, setAvailableLeaves ] = useState(0);
  const [ consumedLeaves, setConsumedLeaves ] = useState(0);
  const [ displayLoader, toggleLoader ] = useState(false);
  const data = [
    {
      type: `Consumed : ${consumedLeaves}`,
      value: consumedLeaves,
    },
    {
      type: `Available : ${availableLeaves}`,
      value: availableLeaves,
    }
  ];
  const config = {
    width: 200,
    height: 200,
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    color: [ COLORS.CHART.CONSUMED, COLORS.CHART.AVAILABLE ],
    radius: 1,
    innerRadius: 0.8,
    label: false,
    legend: {
      position: 'bottom'
    },
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: 13
        },
        content: `${consumedLeaves}/${availableLeaves}`,
      },
    },
  };

  useEffect(() => {
    getStaffLeaveData();
  }, []);

  const getStaffLeaveData = async () => {
    toggleLoader(true);
    const { year } = dateInDetail();
    const {
      code,
      availableLeaves: available,
      consumedLeaves: consumed,
    } = await CALL_API('leave-statistics', 'post', {
      year
    });

    toggleLoader(false);
    if (code === STATUS_CODE.SUCCESS) {
      setAvailableLeaves(available);
      setConsumedLeaves(consumed);
    }
  }

  return (
    <StyledRowContainer>
      <Col span={24}>
        <RoundIcon>
          <CustomIcon name='calendar2' />
        </RoundIcon>
        <StyledTitle>ANNUAL LEAVE</StyledTitle>
      </Col>
      <Col md={{ span: 8, offset: 8 }} sm={24} xs={24} className='align-center chart-container'>
        <Spin spinning={displayLoader}>
          <Pie {...config} />
        </Spin>
      </Col>
    </StyledRowContainer>
  );
}

const mapStateToProps = state => ({
    userName: getUserName(state),
    systemTime: getTime(state),
    ampm: getAMPM(state),
    longDay: getLongDay(state),
});

export default connect(mapStateToProps, null)(StaffAnnualLeave);

import React, { useEffect, useState } from "react";
import { Card, Avatar, Tooltip, Row, Col, Menu, Dropdown } from "antd";
// Redux
import { connect } from "react-redux";
import {
  getUserName,
  getUserRoleId,
  getUnreadChatMessageList,
} from "redux/selectors";
import styled from "@emotion/styled";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { startCase } from "lodash";
import COLORS from "common/Colors";
import ViewMore from "organisms/ViewMore";

const StyledCard = styled(Card)`
  min-height: 350px;
  padding-left: 1%;
  text-align: center;
  border-radius: 5px;
  padding: 2%;
  position: relative;
  .threedot {
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
  }
  .ant-card-body {
    padding-bottom: 15px !important;
  }
`;

const StyledAvatar = styled(Avatar)`
  background-color: ${COLORS.PRIMARY};
  vertical-align: middle;
  font-size: 3rem;
  height: 100px;
  text-align: center;
  width: 100px;
  .ant-avatar-string {
    margin-top: 2rem;
  }
`;

const StyleName = styled.h1`
  padding-top: 1rem;
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
  color: #5b86e5;
  word-wrap: break-word;
`;

const StyleEmployeeId = styled.h2`
  padding: 5px;
  font-size: 0.8rem;
  margin-bottom: 0px;
  text-align: left;
`;

const StyleEmailId = styled.h3`
  text-align: left;
  padding: 5px;
  font-size: 0.7rem;
  color: #5b86e5;
  word-wrap: break-word;
`;

const StyleDepartment = styled.h4`
  display:flex;
  gap: 5px;
  text-align: left;
  width: 100%;
  padding: 5px;
  font-size: 0.8rem;
  margin-bottom: 0px;
  b{
    white-space: nowrap;
  }
`;

const StyleRole = styled.h5`
  padding: 5px;
  font-size: 0.8rem;
  margin-bottom: 0px;
  text-align: left;
`;

const StyledViewMenu = styled.div`
  width: 100px;
  cursor: pointer;
`;

function EmployeeCard({
  id,
  name,
  email_id,
  Department,
  Role,
  ViewEmployee,
  data,
  deletedEmployee,
  profile,
}) {
  const [updateProfile, setUpdatedProfile] = useState("");

  const menu = (
    <StyledViewMenu>
      <Menu>
        <Menu.Item onClick={() => ViewEmployee(data)}>Edit</Menu.Item>
        <Menu.Item onClick={() => deletedEmployee(id)}>Terminate</Menu.Item>
      </Menu>
    </StyledViewMenu>
  );

  useEffect(() => {
    setUpdatedProfile(profile);
  }, [updateProfile]);

  return (
    <StyledCard>
      <Row>
        <Col className="threedot">
          <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
            <MoreVertIcon />
          </Dropdown>
        </Col>
      </Row>
      {profile === null || profile === "" || profile === undefined || profile == "null" ? (
        <StyledAvatar size="default">{startCase(name?.charAt(0))}</StyledAvatar>
      ) : (
        <StyledAvatar src={profile} size="default"></StyledAvatar>
      )}
      <StyleName>
        <Tooltip title={name}>{name}</Tooltip>
      </StyleName>
      <div>
        <StyleEmployeeId>
          <b> Employee ID : </b> {id}
        </StyleEmployeeId>
        <StyleDepartment>
          <b> Department : </b>
          <>{<ViewMore value={Department} />}</>
        </StyleDepartment>
        <StyleRole>
          <b> Role : </b> {Role}
        </StyleRole>
        <StyleEmailId>
          <Tooltip title={email_id}>
            <b> Email Id : </b>
            {email_id}
          </Tooltip>
        </StyleEmailId>
      </div>
    </StyledCard>
  );
}

const mapStateToProps = (state) => ({
  userName: getUserName(state),
  userRoleId: getUserRoleId(state),
  unreadChatMessageList: getUnreadChatMessageList(state),
});

export default connect(mapStateToProps, null)(EmployeeCard);

import React, { useEffect, useState } from "react";
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Select,
  InputNumber,
  Checkbox,
  message,
  Spin,
} from "antd";
import moment from "moment";
// Redux
import { connect } from "react-redux";
import { getLeaveCategoryList, getUserInfo } from "redux/selectors";
import styled from "@emotion/styled";
import { NON_EMPTY_REGEX, STATUS_CODE } from "common/Constants";
import Button from "atoms/Button";
import {
  addDaysinDate,
  getKenyanDateTime,
  getMySqlDate,
  momentDate,
} from "utils/Date";
import { displayErrorModal } from "common/common";
import { CALL_API } from "common/API";
import { get } from "lodash";

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding-top: 20px;
    padding-bottom: 30px;
  }
  .ant-modal-title {
    font-weight: bold;
    font-weight: 1.2rem;
  }
  .ant-modal-body {
    padding-top: 0px;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-form-item-label {
    font-weight: 500;
  }
  .ant-picker,
  .ant-input-number {
    width: 100%;
  }
`;

const StyledButton = styled(Button)`
  width: 120px;
  border-radius: 5px;
  width: initial;
`;

const StyledFooterDiv = styled.div`
  padding-top: 30px;
`;

function LeaveForm({
  isModalVisible,
  closeModal,
  leaveCategoryList,
  leaveDetails,
  userInfo,
}) {
  const [showLoader, toggleLoader] = useState(false);
  const [showLeaveValidationLoader, toggleLeaveValidationLoader] = useState(false);
  const [isViewOnly, setIsViewOnly] = useState(false);
  const [isCancelRequest, setCancelRequest] = useState(false);
  const [form] = Form.useForm();
  const [halfDay, setHalfDay] = useState(false);
  const [showHalfDayCheckbox, setShowHalfDayCheckbox] = useState(true);
  const { TextArea } = Input;

  const resetForm = () => {
    setShowHalfDayCheckbox(true);
    form.setFieldsValue({
      leave_id: "",
      start_date: momentDate(new Date()),
      end_date: momentDate(new Date()),
      number_of_days: 1,
      leave_type: 7,
      description: "",
    });
  };

  const resetHalfDay = () => {
    setHalfDay(false);
    form.setFieldsValue({ number_of_days: 1 });
  };

  const updateHalfDay = () => {
    const { number_of_days } = form.getFieldsValue();
    const roundedDays = number_of_days;
    if (halfDay === true) {
      if (number_of_days >= 0.5) {
        form.setFieldsValue({
          number_of_days: roundedDays - 0.5,
        });
      }
    } else {
      if (number_of_days >= 0.5) {
        form.setFieldsValue({
          number_of_days: Math.ceil(roundedDays),
        });
      }
    }
  };

  useEffect(() => {
    if (isModalVisible) {
      if (
        moment(leaveDetails?.end_date).format("dddd") == "Saturday" &&
        moment(leaveDetails?.start_date).format("dddd") == "Saturday" &&
        moment(leaveDetails?.start_date).format("L") ==
          moment(leaveDetails?.end_date).format("L")
      ) {
        setShowHalfDayCheckbox(false);
      } else {
        setShowHalfDayCheckbox(true);
      }
    }
  }, [isModalVisible]);

  useEffect(() => {
    updateHalfDay();
  }, [halfDay]);

  useEffect(() => {
    const {
      user_leave_id = "",
      start_date = "",
      end_date = "",
      reason = "",
      number_of_days = 0,
      leave_category_id,
      isViewOnly,
      isCancel = false,
    } = leaveDetails;
    if (user_leave_id !== "") {
      setIsViewOnly(isViewOnly);
      setCancelRequest(isCancel);
      form.setFieldsValue({
        leave_id: user_leave_id,
        start_date: momentDate(start_date),
        end_date: momentDate(end_date),
        number_of_days,
        leave_type: leave_category_id,
        description: reason,
        remark: "",
      });
    } else if (start_date && end_date) {
      setCancelRequest(isCancel);
      form.setFieldsValue({
        start_date: momentDate(start_date),
        end_date: momentDate(end_date),
      });
    }
  }, [leaveDetails, isModalVisible]);

  // note: first parameter is needed
  const onFinish = async (values, saveLeave = true) => {
    if (saveLeave) toggleLoader(true);
    else toggleLeaveValidationLoader(true);

    const {
      start_date,
      end_date,
      number_of_days,
      leave_type,
      description,
      remark,
    } = form.getFieldsValue();
    const { user_leave_id = "" } = leaveDetails;
    const { code, leaveBalance, totalNumberOfDaysInLeave } = await CALL_API(
      "save-leave",
      "post",
      {
        leave_id: user_leave_id,
        start_date: getMySqlDate(start_date),
        end_date: getMySqlDate(end_date),
        number_of_days,
        leave_category_id: leave_type,
        reason: description,
        decline_reason: isCancelRequest ? remark : "",
        is_cancel_request: isCancelRequest,
        isSaving: saveLeave,
        department_id: get(userInfo, "staff_department_id", ""),
        old_number_of_day: leaveDetails?.number_of_days
          ? leaveDetails?.number_of_days
          : "",
      }
    );
    const payloadVariable = {
      leave_id: user_leave_id,
      start_date: getMySqlDate(start_date),
      end_date: getMySqlDate(end_date),
      number_of_days,
      leave_category_id: leave_type,
      reason: description,
      decline_reason: isCancelRequest ? remark : "",
      is_cancel_request: isCancelRequest,
      isSaving: saveLeave,
    };
    if (saveLeave) toggleLoader(false);
    else toggleLeaveValidationLoader(false);
    if (code === STATUS_CODE.SOMETHING_WENT_WRONG) {
      message.error(`Oops!! something went wrong`);
    } else if (code === STATUS_CODE.INVALID_PAYLOAD) {
      message.error(`Invalid payload. Please enter correct data`);
    } else if (code === STATUS_CODE.RECORD_EXIST) {
      message.error(`Leave already exists`);
    } else if (code === STATUS_CODE.ANNUAL_LEAVE_AVAILABLE) {
      message.error(
        `You can not apply compassionate leave. You still have annual leaves.`
      );
    } else if (code === STATUS_CODE.LEAVE_EXCEED) {
      form.setFieldsValue({
        number_of_days: totalNumberOfDaysInLeave,
      });
      displayErrorModal(
        `You are exceeding your leave quota. your current leave balance is ${leaveBalance}`
      );
    } else if (code === STATUS_CODE.SUCCESS) {
      if (saveLeave) {
        if (
          payloadVariable.number_of_days === leaveDetails.number_of_days &&
          payloadVariable.leave_category_id ===
            leaveDetails.leave_category_id &&
          payloadVariable.reason === leaveDetails.reason
        ) {
          message.error("Leave already exist");
        } else {
          message.success(
            isCancelRequest
              ? "Leave application request has been submitted"
              : `Leave has been ${user_leave_id ? "updated" : "applied"}`
          );
          form.resetFields();
          closeModal(true);
          setShowHalfDayCheckbox(true);
        }
      } else {
        form.setFieldsValue({
          number_of_days: totalNumberOfDaysInLeave,
        });
      }
    }
  };

  const restrictPastDate = (current, element) => {
    let customDate = moment("2022-08-01").format("YYYY-MM-DD");
    if (element === "end_date") {
      return current < moment(form.getFieldValue("start_date"));
    }
    return current && current < moment(customDate, "YYYY-MM-DD");
  };

  const validateDateRange = () => {
    const endDate = moment(form.getFieldValue("end_date"));
    const startDate = moment(form.getFieldValue("start_date"));
    if (endDate < startDate) {
      form.setFieldsValue({ end_date: startDate });
    } else {
      onFinish({}, false);
    }
  };

  const dateChange = () => {
    onFinish({}, false);
    updateHalfDay();
  };

  const hideHalfDay = () => {
    const { start_date, end_date } = form.getFieldsValue();
    if (
      moment(end_date).format("dddd") == "Saturday" &&
      moment(start_date).format("dddd") == "Saturday" &&
      moment(start_date).format("L") == moment(end_date).format("L")
    ) {
      setShowHalfDayCheckbox(false);
    } else {
      setShowHalfDayCheckbox(true);
    }
  };

  const updateEndDate = () => {
    const { start_date, number_of_days } = form.getFieldsValue();
    if (number_of_days >= 1) {
      const roundedDays = Math.ceil(number_of_days);
      form.setFieldsValue({
        number_of_days: roundedDays,
        end_date: momentDate(addDaysinDate(start_date, roundedDays - 1)),
      });
    }
  };

  const checkBoxStyle = {
    position: "relative",
    bottom: "15px",
  };

  return (
    <StyledModal
      title="Leave Details"
      visible={isModalVisible}
      onCancel={() => {
        closeModal(false);
        resetHalfDay();
        resetForm();
      }}
      width={600}
      footer={[]}
    >
      <Form
        form={form}
        name="leaveform"
        layout="vertical"
        initialValues={{
          leave_id: "",
          start_date: momentDate(getKenyanDateTime()),
          end_date: momentDate(getKenyanDateTime()),
          number_of_days: 1,
          leave_type: 7,
          description: "",
          remark: "",
        }}
        onFinish={onFinish}
      >
        <Row gutter={20}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label="Start Date"
              name="start_date"
              rules={[
                {
                  required: true,
                  pattern: NON_EMPTY_REGEX,
                  message: "Please enter start date!",
                },
                () => ({
                  // note: First parameter is needed
                  validator(rule, value) {
                    const date = moment(value);
                    return date.isValid()
                      ? Promise.resolve()
                      : Promise.reject();
                  },
                }),
              ]}
            >
              <DatePicker
                onChange={() => {
                  validateDateRange();
                  hideHalfDay();
                }}
                disabled={isViewOnly}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.start_date !== currentValues.start_date
              }
            >
              {() => (
                <Form.Item
                  label="End Date"
                  name="end_date"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "Please enter end date!",
                    },
                    () => ({
                      // note: First parameter is needed
                      validator(rule, value) {
                        const date = moment(value);
                        return date.isValid()
                          ? Promise.resolve()
                          : Promise.reject();
                      },
                    }),
                  ]}
                >
                  <DatePicker
                    disabledDate={(current) =>
                      restrictPastDate(current, "end_date")
                    }
                    onChange={() => {
                      dateChange();
                      setHalfDay(false);
                      hideHalfDay();
                    }}
                    disabled={isViewOnly}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12}>
            <Spin spinning={showLeaveValidationLoader}>
              <Form.Item label="No. of Days" name="number_of_days">
                <InputNumber min={0.5} onChange={updateEndDate} disabled />
              </Form.Item>
            </Spin>

            {showHalfDayCheckbox && (
              <Checkbox
                style={checkBoxStyle}
                checked={halfDay}
                onChange={(e) => {
                  setHalfDay((prevCheck) => !prevCheck);
                }}
              >
                Half day
              </Checkbox>
            )}
          </Col>

          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label="Leave Type"
              name="leave_type"
              rules={[
                {
                  required: true,
                  pattern: NON_EMPTY_REGEX,
                  message: "Please select leave type!",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search leave type"
                name="leave_type"
                optionFilterProp="leave_category_name"
                filterOption={(input, option) =>
                  option.leave_category_name
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) => {
                  optionA.leave_category_name
                    .toLowerCase()
                    .localeCompare(optionB.leave_category_name.toLowerCase());
                }}
                options={leaveCategoryList}
                fieldNames={{
                  label: "leave_category_name",
                  value: "leave_category_id",
                }}
                disabled={isViewOnly}
                onChange={()=>validateDateRange()}
              />
            </Form.Item>
          </Col>
        </Row>

        {!isViewOnly && (
          <div>
            {isCancelRequest && (
              <Form.Item shouldUpdate>
                {({ getFieldValue }) => {
                  const leave_type = getFieldValue("leave_type");
                  return (
                    leave_type !== true && (
                      <Form.Item
                        label="Detailed Reason"
                        name="description"
                        rules={[
                          {
                            required: true,
                            pattern: NON_EMPTY_REGEX,
                            message: "Please enter leave reason!",
                          },
                        ]}
                      >
                        <TextArea
                          placeholder="Type something.."
                          maxLength={500}
                          disabled={isViewOnly}
                          autoSize={{ minRows: 2, maxRows: 6 }}
                        />
                      </Form.Item>
                    )
                  );
                }}
              </Form.Item>
            )}
            {!isCancelRequest && (
              <Form.Item shouldUpdate>
                {({ getFieldValue }) => {
                  const leave_type = getFieldValue("leave_type");
                  return (
                    (leave_type !== 7 || true) && (
                      <Form.Item
                        label="Detailed Reason"
                        name="description"
                        rules={[
                          {
                            required: leave_type !== 7 ? true : false,
                            pattern: NON_EMPTY_REGEX,
                            message: "Please enter leave reason!",
                          },
                        ]}
                      >
                        <TextArea
                          laceholder="Type something.."
                          maxLength={500}
                          disabled={isViewOnly}
                          autoSize={{ minRows: 2, maxRows: 6 }}
                        />
                      </Form.Item>
                    )
                  );
                }}
              </Form.Item>
            )}
          </div>
        )}
        {!isViewOnly && (
          <StyledFooterDiv className="align-right">
            {!isCancelRequest && (
              <StyledButton
                type="primary"
                onClick={() => {
                  resetForm();
                  resetHalfDay();
                }}
                disabled={showLeaveValidationLoader}
              >
                Reset All
              </StyledButton>
            )}
            <StyledButton
              loading={showLoader}
              type="primary"
              htmlType="submit"
              disabled={showLeaveValidationLoader}
            >
              {showLoader ? "Submitting" : "Submit"}
            </StyledButton>
          </StyledFooterDiv>
        )}
      </Form>
    </StyledModal>
  );
}

const mapStateToProps = (state) => ({
  leaveCategoryList: getLeaveCategoryList(state),
  userInfo: getUserInfo(state),
});

export default connect(mapStateToProps, null)(LeaveForm);

import React from "react";
import { Row, Col, Avatar } from "antd";
import { useHistory } from "react-router-dom";
import styled from "@emotion/styled";
import { map, size, startCase, uniqBy, filter, get } from "lodash";
import Icon from "atoms/Icon";
// Redux
import { connect } from "react-redux";
import {
  getUserRoleId,
  getDepartmentUserList,
  getUserList,
  getUserInfo,
} from "redux/selectors";
import { DEVICE } from "common/Device";

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
  @media ${DEVICE.tablet} {
    margin-top: 20px !important;
  }
`;

const StyledNameContainer = styled.div`
  border: solid 1px #9b9b9b;
  border-radius: 3px;
  .name-title {
    background-color: #f0f2f5;
    padding: 12px;
    font-weight: bold;
    font-size: 0.8rem;
  }
  .view-all {
    text-align: center;
    font-size: 13px;
    padding: 10px 5px;
    color: #5b86e5;
    font-weight: bold;
    cursor: pointer;
  }
`;

const StyledContactRow = styled(Row)`
  padding: 5px;
  &.grey {
    background: #f6f6f6;
  }
  .avatar {
    padding-left: 10px;
  }
  .emp-name {
    padding-top: 4px;
    font-size: 11px;
  }
  .message {
    text-align: right;
    padding: 3px;
    padding-right: 10px;
    svg {
      vertical-align: bottom;
      cursor: pointer;
    }
  }
`;

const StyledAvatar = styled(Avatar)`
  background: #5b86e5;
`;

function AllEmployees({ userRoleId, userList, departmentUserList, userInfo }) {
  let history = useHistory();
  let users = uniqBy(
    userRoleId === 1 || userRoleId === 6 ? userList : departmentUserList,
    "user_id"
  );
  users = filter(users, (v) => v.user_id !== get(userInfo, "employer_id", ""));
  return (
    <>
      <StyledTitle>
        {userRoleId === 1 || userRoleId === 6 ? "ALL EMPLOYEES" : "DEPARTMENT"}
      </StyledTitle>
      <StyledNameContainer>
        <div className="name-title">Names:</div>
        {map(users.slice(0, 8), ({ user_id, name, profile_url }, index) => (
          <StyledContactRow key={index} className={index % 2 ? "" : "grey"}>
            <Col span={6} className="avatar">
              {profile_url === null || profile_url ===  "" || profile_url ==  "null" || profile_url === undefined ? (
                <StyledAvatar>{startCase(name.charAt(0))}</StyledAvatar>
              ) : (
                <StyledAvatar src={profile_url} />
              )}
            </Col>
            <Col span={12} className="emp-name">
              {name}
            </Col>
            <Col span={6} className="message">
              <Icon
                name="chat"
                onClick={() => history.push(`/chat/${user_id}`)}
              />
            </Col>
          </StyledContactRow>
        ))}
        {size(users) > 8 && (
          <div className="view-all" onClick={() => history.push(`/team`)}>
            View all
          </div>
        )}
      </StyledNameContainer>
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  userList: getUserList(state),
  departmentUserList: getDepartmentUserList(state),
  userInfo: getUserInfo(state),
});

export default connect(mapStateToProps, null)(AllEmployees);

import React, { useEffect, useState } from "react";
import { Modal, Row, Col } from "antd";
import velogic from "../assets/gcs-velogic.png";
import { useHistory } from "react-router-dom";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { map } from "lodash";
import COLORS from "common/Colors";
import styled from "styled-components";
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getRoleList, getUserRoleId } from "redux/selectors";
import { setUserRole } from "redux/actions";
import { DEVICE } from "common/Device";
import Header from "molecules/Header";

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 1.5rem 3rem;
  }
  .select-role-title {
    margin-bottom: 0rem;
  }
  .select-role-sub-title {
    color: #8a8a8a;
    padding-bottom: 2rem;
  }
`;

const ModalHeaderRow = styled(Row)`
  padding-bottom: 2rem;
`;

const StyledRoleCol = styled(Col)`
  padding: 2rem 3rem;
  text-align: center;
  .role-button {
    width: 200px;
    margin: auto;
    padding: 0.8rem 1rem;
    box-shadow: 0px 0px 5px ${COLORS.GREY};
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
  }
  .role-button.selected {
    background: ${COLORS.PRIMARY};
    color: ${COLORS.WHITE};
  }
  @media ${DEVICE.tablet} {
    padding: 1rem;
    .role-button {
      width: 100%;
    }
  }
`;

const StyledRolsSubmitButtonContainer = styled.div`
  padding-top: 2rem;
  text-align: right;
  .container {
    display: none;
  }
  .ant-btn {
    width: 8rem;
    font-weight: bold;
    height: 40px;
    background: ${COLORS.PRIMARY};
  }
  @media ${DEVICE.mobileL} {
    text-align: center;
  }
`;
const StyledTitle = styled.div`
  h4.select-role-sub-title {
    font: normal normal normal 14px/24px LatoWeb !important;
  }
  h2.select-role-title {
    font: normal normal normal 21px/29px LatoWeb;
  }
`;

const ModalBrandLogoImage = styled.img`
  height: 30px;
`;
function SwitchUserModal({
  userRoleModal,
  closeModal,
  userRole,
  userRoleId,
  roleList,
  onDashboardPg,
}) {
  const [selectedRole, setSelectedRole] = useState(userRoleId);
  let history = useHistory();

  useEffect(() => {
    setSelectedRole(userRoleId);
  }, [userRoleId]);

  const redirectToDashboard = (key) => {
    userRole(key);
    closeModal();
    if (onDashboardPg === true) {
      history.push("/");
    }
  };
  const redirectToDashboard1 = (key) => {
    userRole(key);
    closeModal();
  };

  return (
    <StyledModal
      centered
      visible={userRoleModal}
      closable={false}
      footer={false}
      keyboard={false}
      maskClosable={false}
      width="70%"
    >
      <ModalHeaderRow>
        <Col span={12}>
          <ModalBrandLogoImage src={velogic} />
        </Col>
        <Col span={12} className="align-right">
          {onDashboardPg && (
            <IconButton onClick={() => redirectToDashboard1(selectedRole)}>
              <CloseIcon />
            </IconButton>
          )}
        </Col>
      </ModalHeaderRow>
      <StyledTitle>
        <h2 className="select-role-title">Select user role</h2>
        <h4 className="select-role-sub-title">
          Let us know who you are logging in as so that we can provide you with
          relevant content.
        </h4>
      </StyledTitle>
      <Row>
        {map(roleList, ({ role_id: key, role_name: text }) => {
          return (
            <StyledRoleCol md={12} sm={24} xs={24} key={key}>
              <div
                onClick={() => {
                  setSelectedRole(key);
                  redirectToDashboard(key);
                }}
                className={`role-button ${
                  key === selectedRole && onDashboardPg ? "selected" : ""
                }`}
              >
                {text}
              </div>
            </StyledRoleCol>
          );
        })}
      </Row>

      <StyledRolsSubmitButtonContainer>
        <div className="container">
          <Header redirectToDashboard={redirectToDashboard} />
        </div>
      </StyledRolsSubmitButtonContainer>
    </StyledModal>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  roleList: getRoleList(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      userRole: setUserRole,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SwitchUserModal);

import React from "react";
import { Row, Col } from "antd";
import { Pie } from "@ant-design/plots";
import styled from "@emotion/styled";
import RoundIcon from "atoms/RoundIcon";
import Icon from "atoms/Icon";
import { last, map, get, first } from "lodash";

const StyledChartContainer = styled.div`
  border: solid 1px #9b9b9b;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 15px;
  .styled-circle {
    height: 30px;
    width: 30px;
    svg {
      margin: 4px;
    }
  }
  .report-title {
    font-weight: bold;
    font-size: 0.8rem;
    padding-top: 3px;
    padding-left: 10px;
    text-transform: uppercase;
  }
  .report-title1 {
    font-weight: bold;
    font-size: 0.7rem;
    padding-top: 3px;
    span {
      color: blue;
      font-weight: normal;
    }
  }
  .legends {
    padding-top: 2rem;
    padding-left: 5px;
    div {
      padding-bottom: 5px;
    }
  }
`;

const StyledColorBox = styled.span`
  ${({ color }) => color && `background: ${color};`}
  padding: 0px 8px;
  margin-right: 10px;
`;

function LeaveDonutChart({ iconName, title, data, staffLeave }) {
  const statisticStyle = { fontSize: 13 };
  const config = {
    width: 150,
    height: 150,
    appendPadding: 6,
    data,
    angleField: "value",
    colorField: "type",
    color: map(data, "color", []),
    radius: 1,
    innerRadius: 0.8,
    legend: false,
    label: false,
    statistic: {
      title: false,
      content: {
        style: statisticStyle,
        content: `${get(last(data), "value", "")}/${get(
          first(data),
          "value",
          ""
        )}`,
      },
    },
  };
  return (
    <StyledChartContainer>
      <Row>
        <Col span={4}>
          <RoundIcon>{iconName && <Icon name={iconName} />}</RoundIcon>
        </Col>
        <Col span={20} className="report-title">
          {title}
        </Col>
        <Col span={12} className="legends">
          <div>
            <StyledColorBox color={get(first(data), "color")}></StyledColorBox>
            <span>
              {get(first(data), "type", "")} :{" "}
              {data[0]?.value == null ? "null" : get(first(data), "value", "")}
            </span>
          </div>
          <div>
            <StyledColorBox color={get(last(data), "color")}></StyledColorBox>
            <span>
              {get(last(data), "type", "")} :{" "}
              {data[1]?.value == null ? "null" : get(last(data), "value", "")}
            </span>
          </div>
          {(staffLeave == "Annual Leave" ||
            staffLeave == "Department's Annual Leave Balance") && (
            <div className="report-title1">
              Last Year Leave Balance :{" "}
              <span>{get(first(data), "lastYearLeaveBalance", "")}</span>
            </div>
          )}
        </Col>
        <Col span={12}>
          <Pie {...config} />
        </Col>
      </Row>
    </StyledChartContainer>
  );
}

export default LeaveDonutChart;

import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Table,
  Spin,
  Select,
  DatePicker,
  Space,
  Form,
  message,
} from "antd";
import Button from "atoms/Button";
import moment from "moment";
import styled from "@emotion/styled";
import { CSVLink } from "react-csv";
// Redux
import { connect } from "react-redux";
import {
  getUserRoleId,
  getSelectedDepartmentId,
  getStatusListFromID,
  getUserInfo,
  getLeaveCategoryList,
} from "redux/selectors";
import { bindActionCreators } from "redux";
import { toggleProcessingModal } from "redux/actions";
import { CALL_API } from "common/API";
import {
  MONTH_LIST,
  APPROVAL_FILTER_TYPES,
  STATUS_CODE,
} from "common/Constants";
import {
  addDaysinDate,
  dateInDetail,
  getMonthDateRange,
  getKenyanDateTime,
  addMonthinDate,
  getMySqlDate,
  momentDate,
  getinitialdate,
} from "utils/Date";
import COLORS from "common/Colors";
import {
  find,
  get,
  includes,
  reduce,
  concat,
  map,
  result,
  filter,
} from "lodash";
import ViewMore from "organisms/ViewMore";

const StyledTable = styled(Table)`
  padding-top: 20px;
  table {
    width: 100% !important;
  }
  .ant-table-content {
    overflow-x: auto;
    max-height: 500px;
    white-space: nowrap;
  }
  .ant-table-cell {
    padding: 20px;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
    white-space: nowrap;
  }
  .non-white {
    background: #f9f9f9;
  }
  .normal-column {
    min-width: 200px;
    white-space: normal;
  }
`;

const StyledApprovalSearchForm = styled(Form)`
  .ant-form-item-with-help {
    margin-bottom: 0px;
    .ant-form-item-explain-connected {
      display: none;
    }
  }
  .ant-form-item {
    display: inline-block;
  }
  .outer-form {
    margin-right: 0px;
  }
  .ant-select-selector {
    max-width: 200px;
    min-width: 180px;
    margin-bottom: 10px;
    width: 100%;
  }
`;
const StyledButton = styled(Button)`
  width: 120px;
  border: 1px solid ${COLORS.PRIMARY};
  color: ${COLORS.PRIMARY};
  width: initial;
  background: transparent;
`;

function Myleavereport({
  userRoleId,
  statusListFromID,
  selectedDepartmentId,
  userInfo,
  leaveCategoryList,
}) {
  const [leaveList, setLeaveList] = useState([]);
  const [CSVLeaveList, setCSVLeaveList] = useState([]);
  const [displayTaskLoader, setDisplayTaskLoader] = useState(false);
  const [leaveStatusId, setLeaveStatusId] = useState(4);
  const [form] = Form.useForm();
  const csvLinkEl = useRef();
  //  Note: Second parameter is needed
  const data = map(leaveList, ({ having_cancel_request }, index) => ({
    having_cancel_request,
  }));
 
  const data1 = leaveList.filter((val) => {
    if (data === "") {
      return val;
    } else if (val.status_name.toLowerCase().includes("wait".toLowerCase())) {
      return val;
    } else if (
      val.status_name.toLowerCase().includes("Approved".toLowerCase())
    ) {
      if (
        JSON.stringify(val.having_cancel_request)
          .toLowerCase()
          .includes("1".toLowerCase())
      ) {
        return val;
      }
    }
  });

  const CSVHeaders = [
    { label: "Leave Type", key: "leave_category_name" },
    { label: "Start Date", key: "start_date" },
    { label: "End Date", key: "end_date" },
    { label: "Days", key: "number_of_days" },
    { label: "Approved by", key: "approved_rejected_byName" },
    { label: "Rejected by", key: "approved_rejected_byName" },
    { label: "Description", key: "reason" },
  ];

  const columns = [
    {
      title: "Leave Type",
      dataIndex: "leave_category_name",
      key: "leave_category_name",
      width: "10%",
    },
    {
      title: "Department",
      dataIndex: "department_name",
      key: "department_name",
      width: "10%",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      width: "10%",
      render: (_, record) => {
        if (record?.new_start_date !== null) {
          const dateFormat = moment(record?.new_start_date).format(
            "DD MMM YYYY"
          );
          return `${dateFormat}`;
        } else {
          const dateFormat = moment(record?.start_date).format("DD MMM YYYY");
          return `${dateFormat}`;
        }
      },
    },
    {
      title: "Start Day",
      dataIndex: "start_date",
      key: "start_date",
      width: "10%",
      render: (_, record) => {
        if (record?.new_start_date === null) {
          const getdayAlone = moment(record?.start_date).format("dddd");
          return `${getdayAlone}`;
        } else {
          const getdayAlone = moment(record?.new_start_date).format("dddd");
          return `${getdayAlone}`;
        }
      },
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      width: "10%",
      render: (_, record) => {
        if (record?.new_end_date !== null) {
          const dateFormat = moment(record?.new_end_date).format("DD MMM YYYY");
          return `${dateFormat}`;
        } else {
          const dateFormat = moment(record?.end_date).format("DD MMM YYYY");
          return `${dateFormat}`;
        }
      },
    },
    {
      title: "End Day",
      dataIndex: "end_date",
      key: "end_date",
      width: "10%",
      render: (_, record) => {
        if (record?.new_end_date === null) {
          const getdayAlone = moment(record?.end_date).format("dddd");
          return `${getdayAlone}`;
        } else {
          const getdayAlone = moment(record?.new_end_date).format("dddd");
          return `${getdayAlone}`;
        }
      },
    },
    {
      title: "Days",
      dataIndex: "number_of_days",
      key: "number_of_days",
      width: "10%",
      render: (_, record) => {
        if (
          record?.new_number_of_days === null ||
          record?.new_number_of_days === ""
        ) {
          return record?.number_of_days;
        } else {
          return record?.new_number_of_days;
        }
      },
    },
  ];

  if (leaveStatusId === 5) {
    columns.push({
      title: "Approved by",
      dataIndex: "approved_rejected_byName",
      key: "approved_rejected_byName",
      width: "10%",
    });
  }
  if (leaveStatusId === 6) {
    columns.push(
      {
        title: "Rejected by",
        dataIndex: "approved_rejected_byName",
        key: "approved_rejected_byName",
        width: "10%",
      },
      {
        title: "Decline Reason",
        dataIndex: "decline_reason",
        key: "decline_reason",
        width: "10%",
        className: "normal-column",
        render: (reason) => {
          return (
            <>
              <ViewMore value={reason} />
            </>
          );
        },
      }
    );
  } else {
    columns.push({
      title: "Description",
      dataIndex: "reason",
      key: "reason",
      width: "30%",
      className: "normal-column",
      render: (reason) => {
        return (
          <>
            <ViewMore value={reason} />
          </>
        );
      },
    });
  }

  const data2 = leaveList?.filter((val) => {
    if (val.having_cancel_request == 0) {
      return val;
    }
  });
  useEffect(() => {
    getLeaveList();
  }, [
    selectedDepartmentId,
    userRoleId,
    form.getFieldValue("end_date"),
    form.getFieldValue("month"),
    form.getFieldValue("start_date"),
    form.getFieldValue("duration_type"),
    form.getFieldValue("status_id"),
    form.getFieldValue("leave_type"),
  ]);

  // Function to generate Leave report
  const getLeaveList = async () => {
    return new Promise(async (resolve, reject) => {
      setLeaveList([]);
      const { status_id, start_date, duration_type, month, end_date } =
        form.getFieldsValue();
      const { startDateOfMonth, lastDateOfMonth } = getMonthDateRange(
        dateInDetail(getKenyanDateTime()).year,
        get(find(MONTH_LIST, { value: month }), "index", 0)
      );
      if (status_id) {
        setLeaveStatusId(status_id);
        setDisplayTaskLoader(true);
        const { code, leaves = [] } = await CALL_API("leave-list", "post", {
          status_id: status_id === 4 ? "" : status_id,
          department_id:
            userRoleId === 1
              ? [""]
              : userRoleId === 4
              ? [get(userInfo, "staff_department_id", "")]
              : selectedDepartmentId,
          selected_role_id: userRoleId,
          start_date: getMySqlDate(
            duration_type === 2
              ? startDateOfMonth
              : duration_type === 4
              ? momentDate(addMonthinDate(getinitialdate()))
              : start_date
          ),
          end_date: getMySqlDate(
            duration_type === 2
              ? lastDateOfMonth
              : duration_type === 3
              ? weeks(7, getMySqlDate(start_date))
              : duration_type === 4
              ? momentDate(addMonthinDate(getKenyanDateTime(), 3))
              : end_date
          ),
        });

        setDisplayTaskLoader(false);
        if (
          includes([STATUS_CODE.SUCCESS, STATUS_CODE.RECORD_NOT_FOUND], code)
        ) {
          let leaveList = [];
          leaves.map((list) => {
            Object.entries(list).forEach(([key, value]) => {
              leaveList = concat(leaveList, value);
            });
          });
          if (form.getFieldValue("leave_type") === "") {
            setLeaveList(leaveList.reverse());
          } else {
            setLeaveList(
              filter(
                leaveList,
                (item) =>
                  item?.leave_category_id === form.getFieldValue("leave_type")
              )
            );
          }
          resolve(leaveList);
        } else {
          reject([]);
        }
      } else {
        reject();
      }
    });
  };
  const validateDateRange = (date) => {
    const endDate = moment(form.getFieldValue("end_date"));
    if (endDate < date) {
      form.setFieldsValue({ end_date: date });
    }
  };

  function weeks(days, date) {
    var date = new Date(date);
    date.setDate(date.getDate() + days);
    return date.toISOString().slice(0, 10);
  }

  const [fileNamelabel, setFileNameLabel] = useState("");

  // Function to generate CSV file
  const prepareCSV = async () => {
    let res = result(
      find(statusListFromID, (e) => {
        return e.status_id === form.getFieldValue("status_id");
      }),
      "status_name"
    );
    setFileNameLabel(res);
    if (leaveList?.length > 0) {
      setCSVLeaveList(
        reduce(
          leaveStatusId === 4 ? data1 : leaveStatusId === 5 ? data2 : leaveList,
          function (result, leave) {
            if (leaveStatusId === 4) {
              result.push({
                "Start Date":
                  leave?.new_start_date === null
                    ? `${moment(leave?.start_date).format("Do MMM YYYY")}`
                    : `${moment(leave?.new_start_date).format("Do MMM YYYY")}`,
                "Start Day":
                  leave?.new_start_date === null
                    ? `${moment(leave?.start_date).format("dddd")}`
                    : `${moment(leave?.new_start_date).format("dddd")}`,
                "End Date":
                  leave?.new_end_date === null
                    ? `${moment(leave?.end_date).format("Do MMM YYYY")}`
                    : `${moment(leave?.new_end_date).format("Do MMM YYYY")}`,
                "End Day":
                  leave?.new_end_date === null
                    ? `${moment(leave?.end_date).format("dddd")}`
                    : `${moment(leave?.new_end_date).format("dddd")}`,
                "Leave Type": leave?.leave_category_name,
                "Days":
                  leave?.new_number_of_days === null
                    ? leave?.number_of_days
                    : leave?.new_number_of_days,
                "Description": leave?.reason,
              });
            }
            if (leaveStatusId === 5) {
              result.push({
                "Leave Type": leave?.leave_category_name,
                "Start Date":
                  leave?.new_start_date === null
                    ? `${moment(leave?.start_date).format("Do MMM YYYY")}`
                    : `${moment(leave?.new_start_date).format("Do MMM YYYY")}`,
                "Start Day":
                  leave?.new_start_date === null
                    ? `${moment(leave?.start_date).format("dddd")}`
                    : `${moment(leave?.new_start_date).format("dddd")}`,
                "End Date":
                  leave?.new_end_date === null
                    ? `${moment(leave?.end_date).format("Do MMM YYYY")}`
                    : `${moment(leave?.new_end_date).format("Do MMM YYYY")}`,
                "End Day":
                  leave?.new_end_date === null
                    ? `${moment(leave?.end_date).format("dddd")}`
                    : `${moment(leave?.new_end_date).format("dddd")}`,
                "Days":
                  leave?.new_number_of_days === null
                    ? leave?.number_of_days
                    : leave?.new_number_of_days,
                "Approved by": leave?.approved_rejected_byName,
                "Description": leave?.reason,
              });
            }
            if (leaveStatusId === 6) {
              result.push({
                "Leave Type": leave?.leave_category_name,
                "Start Date":
                  leave?.new_start_date === null
                    ? `${moment(leave?.start_date).format("Do MMM YYYY")}`
                    : `${moment(leave?.new_start_date).format("Do MMM YYYY")}`,
                "Start Day":
                  leave?.new_start_date === null
                    ? `${moment(leave?.start_date).format("dddd")}`
                    : `${moment(leave?.new_start_date).format("dddd")}`,
                "End Date":
                  leave?.new_end_date === null
                    ? `${moment(leave?.end_date).format("Do MMM YYYY")}`
                    : `${moment(leave?.new_end_date).format("Do MMM YYYY")}`,
                "End Day":
                  leave?.new_end_date === null
                    ? `${moment(leave?.end_date).format("dddd")}`
                    : `${moment(leave?.new_end_date).format("dddd")}`,
                "Days":
                  leave?.new_number_of_days === null
                    ? leave?.number_of_days
                    : leave?.new_number_of_days,
                "Rejected by": leave?.approved_rejected_byName,
                "Decline Reason": leave?.decline_reason,
              });
            }
            return result;
          },
          []
        )
      );

      setTimeout(() => {
        csvLinkEl.current.link.click();
      });
    } else {
      message.error(`No data available to download.`);
    }
  };

  const paginationOptions = {
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    pageSize: 20,
    showSizeChanger: false,
  };

  const styledSelect = {width: 172}

  return (
    <>
      <Row className="page-title">
        <Col span={12} className="page-title-head">
          Leave History
        </Col>
      </Row>
      <br />
      <Row className="page-title">
        <Col xs={24} sm={19} md={19} className="page-title-head">
          <Space>
            <StyledApprovalSearchForm
              form={form}
              name="approval_form"
              layout="inline"
              initialValues={{
                duration_type: 4,
                month: dateInDetail(getKenyanDateTime()).monthName,
                status_id: 4,
                start_date: momentDate(addMonthinDate(getKenyanDateTime(), -1)),
                end_date: momentDate(addDaysinDate(getKenyanDateTime())),
                leave_type: "",
              }}
              onFinish={getLeaveList}
            >
              <Form.Item
                name="status_id"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  placeholder="Select..."
                  style={styledSelect}
                  items={statusListFromID}
                  fieldNames={{
                    label: "status_name",
                    value: "status_id",
                  }}
                  options={statusListFromID}
                />
              </Form.Item>

              <Form.Item
                name="duration_type"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  placeholder="Select..."
                  style={styledSelect}
                  options={APPROVAL_FILTER_TYPES}
                />
              </Form.Item>

              <Form.Item className="outer-form" shouldUpdate>
                {({ getFieldValue }) => {
                  const duration_type = getFieldValue("duration_type");
                  return duration_type === 1 ? (
                    <>
                      <Row>
                        <Col>
                          <Form.Item
                            name="start_date"
                            rules={[
                              () => ({
                                // note: First parameter is needed
                                validator(rule, value) {
                                  const date = moment(value);
                                  return date.isValid()
                                    ? Promise.resolve()
                                    : Promise.reject();
                                },
                              }),
                            ]}
                          >
                            <DatePicker
                              style={styledSelect}
                              placeholder="Start date"
                              onChange={validateDateRange}
                              allowClear={false}
                            />
                          </Form.Item>
                        </Col>
                        <Col>
                          <Form.Item
                            name="end_date"
                            rules={[
                              () => ({
                                // note: First parameter is needed
                                validator(rule, value) {
                                  const date = moment(value);
                                  return date.isValid()
                                    ? Promise.resolve()
                                    : Promise.reject();
                                },
                              }),
                            ]}
                          >
                            <DatePicker
                              style={styledSelect}
                              placeholder="End date"
                              onChange={validateDateRange}
                              allowClear={false}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  ) : duration_type === 3 ? (
                    <Form.Item
                      name="start_date"
                      rules={[
                        () => ({
                          // note: First parameter is needed
                          validator(rule, value) {
                            const date = moment(value);
                            return date.isValid()
                              ? Promise.resolve()
                              : Promise.reject();
                          },
                        }),
                      ]}
                    >
                      <DatePicker
                        style={styledSelect}
                        placeholder="Start date"
                        onChange={validateDateRange}
                        allowClear={false}
                      />
                    </Form.Item>
                  ) : duration_type === 2 ? (
                    <Form.Item
                      name="month"
                      rules={[
                        {
                          required: true,
                          message: "",
                        },
                      ]}
                    >
                      <Select
                        style={styledSelect}
                        placeholder="Select..."
                        options={MONTH_LIST}
                      />
                    </Form.Item>
                  ) : (
                    ""
                  );
                }}
              </Form.Item>
              <Form.Item name="leave_type">
                <Select
                  showSearch
                  placeholder="Search leave type"
                  name="leave_type"
                  optionFilterProp="leave_category_name"
                  filterOption={(input, option) =>
                    option.leave_category_name
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) => {
                    optionA.leave_category_name
                      .toLowerCase()
                      .localeCompare(optionB.leave_category_name.toLowerCase());
                  }}
                  options={concat(
                    {
                      leave_category_id: "",
                      leave_category_name: "All Categories",
                    },
                    leaveCategoryList
                  )}
                  fieldNames={{
                    label: "leave_category_name",
                    value: "leave_category_id",
                  }}
                />
              </Form.Item>
            </StyledApprovalSearchForm>
          </Space>
        </Col>
        <Col
          xs={{ order: 1, span: 24 }}
          lg={{ order: 2, span: 4 }}
          className="align-right"
        >
          <StyledButton onClick={prepareCSV}>
            Export CSV file
          </StyledButton>
          <CSVLink
            header={CSVHeaders}
            data={CSVLeaveList}
            filename={`myleave-list-${fileNamelabel}.csv`}
            asyncOnClick={true}
            ref={csvLinkEl}
          />
        </Col>
      </Row>
      <Spin spinning={displayTaskLoader}>
        <StyledTable
          dataSource={
            leaveStatusId === 4
              ? data1
              : leaveStatusId === 5
              ? data2
              : leaveList
          }
          columns={columns}
          // note: First parameter is needed
          rowClassName={(record, index) => (index % 2 === 0 ? "" : "non-white")}
          rowKey="user_leave_id"
          pagination={
            (leaveStatusId === 4
              ? data1
              : leaveStatusId === 5
              ? data2
              : leaveList
            ).length > 20
              ? paginationOptions
              : false
          }
        />
      </Spin>
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  statusListFromID: getStatusListFromID(state, [4, 5, 6]),
  selectedDepartmentId: getSelectedDepartmentId(state),
  userInfo: getUserInfo(state),
  leaveCategoryList: getLeaveCategoryList(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateProcessingModal: toggleProcessingModal,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(Myleavereport);
